import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../component/Header";

const apiUrl = process.env.REACT_APP_API_URL;

function Delivery() {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(null);

  const toggleDropdown = (index) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  };

  const currentMonthShortForm = () => {
    const monthIndex = new Date().getMonth();
    const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    return monthNames[monthIndex];
  };

  const [selectedMonth, setSelectedMonth] = useState(currentMonthShortForm());

  const monthToNumber = (month) => {
    const months = { JAN: 1, FEB: 2, MAR: 3, APR: 4, MAY: 5, JUN: 6, JUL: 7, AUG: 8, SEP: 9, OCT: 10, NOV: 11, DEC: 12 };
    return months[month] || 0;
  };

  const parseMonthFromOrderNumber = (orderNumber) => {
    const monthPart = orderNumber.startsWith("OP") ? orderNumber.slice(2, 5) : orderNumber.slice(0, 3);
    return monthPart.toUpperCase();
  };

  const sortByOrderNumber = (data) => {
    return data.sort((a, b) => {
      const [aMonth, aNumber] = [parseMonthFromOrderNumber(a.OrderNumber), parseInt(a.OrderNumber.slice(-2))];
      const [bMonth, bNumber] = [parseMonthFromOrderNumber(b.OrderNumber), parseInt(b.OrderNumber.slice(-2))];
      const aMonthNum = monthToNumber(aMonth);
      const bMonthNum = monthToNumber(bMonth);
      if (aMonthNum !== bMonthNum) {
        return bMonthNum - aMonthNum;
      }
      return aNumber - bNumber;
    });
  };

  const filterDataByMonth = (data, month) => {
    return data.filter((item) => parseMonthFromOrderNumber(item.OrderNumber) === month);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/itemDel`);
      const fetchedData = response.data.map((item) => ({
        ...item,
        imageUrl1: `${apiUrl}/uploads/${item.ImageFilename1}`,
        imageUrl2: `${apiUrl}/uploads/${item.ImageFilename2}`,
      }));
      const sortedData = sortByOrderNumber(fetchedData);
      setData(sortedData);
      setFilteredData(filterDataByMonth(sortedData, selectedMonth));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleMonthChange = (event) => {
    const month = event.target.value;
    setSelectedMonth(month);
    setFilteredData(filterDataByMonth(data, month));
  };

  return (
    <>
      <Header />
      <div className="items-center flex flex-col justify-center mt-5 overflow-auto">
        <div className=" border-1 border-slate-200 mt-20 w-3/4 mb-20 px-5 rounded-md overflow-auto justify-center align-middle">
          <h1 className="font-bold text-2xl">Delivery</h1>
          <div className="w-3/4 mb-5">
            <label htmlFor="month-select" className="font-bold mr-2">Select Month:</label>
            <select id="month-select" onChange={handleMonthChange} value={selectedMonth}>
              <option value="">All</option>
              <option value="JAN">January</option>
              <option value="FEB">February</option>
              <option value="MAR">March</option>
              <option value="APR">April</option>
              <option value="MAY">May</option>
              <option value="JUN">June</option>
              <option value="JUL">July</option>
              <option value="AUG">August</option>
              <option value="SEP">September</option>
              <option value="OCT">October</option>
              <option value="NOV">November</option>
              <option value="DEC">December</option>
            </select>
          </div>

          <table className="border-collapse table-auto w-full mb-10 overflow-x-scroll">
            <thead>
              <tr>
                <th className="border-b-1 pb-3 border-slate-600 text-left bg-gray-200">#</th>
                <th className="border-b-1 pb-3 border-slate-600 text-left bg-gray-200">Model</th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-300">Phone Number</th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-300">Place</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => (
                <tr key={index}>
                  <td className="border-b-1 pb-3 border-slate-600 text-left font-bold text-slate-800 m-0 bg-gray-200">
                    {item.OrderNumber}
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                    {item.Model}
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                    <a href={`tel:${item.phone}`} className="text-blue-600 underline">
                      {item.phone}
                    </a>
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                    {item.place}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex flex-row">
            <h1 className="text-2xl font-bold mr-2">Total: </h1>
            <p className="text-2xl underline"> {filteredData.length}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Delivery;
