// src/PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element, roles }) => {
  const token = localStorage.getItem('token');
  const userRole = localStorage.getItem('role');

  // Restrict access based on role
  if (roles && !roles.includes(userRole)) {
    return <Navigate to="/login" />;
  }

  return token ? element : <Navigate to="/login" />;
};

export default PrivateRoute;
