// src/components/Header.js
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from '../images/1122.png';

const HeaderContainer = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  background: ${({ isScrolled, backgroundColor }) => isScrolled ? backgroundColor : backgroundColor};
  transition: background 0.3s ease;
  z-index: 1000;
  color: ${({ isScrolled }) => (isScrolled ? "white" : "white")};
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LogoImage = styled.img`
  height: 40px;
  width: 40px;
`;

const LogoText = styled.p`
  margin-left: 10px;
  font-size: 20px;
`;
const LogoText1 = styled.p`
  margin-left: 10px;
  font-size: 20px;
  padding: 3px 10px;
  border-radius: 8px;
  background-color: white;
`;

const NavLinks = styled.ul`
  display: flex;
  align-items: center;
  justify-items: center;
  list-style: none;
`;

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  // Determine background color based on the current path
  const getTitle = () => {
    switch (location.pathname) {
      case "/shipment":
        return "Shipment";
      case "/china":
        return "CN";
      case "/customerService":
        return "Customer Service";
      case "/admin":
        return "Admin";
      case "/finance":
        return "Finance";
      case "/financeAdmin":
        return "Finance";
      case "/delivery":
        return "Delivery";
      default:
        return "";
    }
  };

  const getBackground = () => {
    switch (location.pathname) {
      case "/shipment":
        return "bg-red-900";
      case "/china":
        return "bg-slate-600";
      case "/customerService":
        return "bg-slate-800";
      case "/admin":
        return "bg-black";
      case "/finance":
        return "bg-green-800";
      case "/financeAdmin":
        return "bg-green-800";
      case "/delivery":
        return "bg-blue-300";
      default:
        return "bg-black";
    }
  };

  const getColor = () => {
    switch (location.pathname) {
      case "/shipment":
        return "text-red-900";
      case "/china":
        return "text-slate-600";
      case "/customerService":
        return "text-slate-800";
      case "/admin":
        return "text-black";
      case "/finance":
        return "text-green-800";
      case "/financeAdmin":
        return "text-green-800";
      case "/delivery":
        return "text-blue-500";
      default:
        return "";
    }
  };

  return (
    <HeaderContainer className={getBackground()}>
      <Link>
        <LogoContainer>
          <LogoImage src={logo} alt="logo" />
          <LogoText>HuluOrder</LogoText>
          <LogoText1 className={getColor()}>{getTitle()}</LogoText1>
        </LogoContainer>
      </Link>
      <NavLinks>
        <button className="text-lg" onClick={handleLogout}>Logout</button>
      </NavLinks>
    </HeaderContainer>
  );
};

export default Header;
