import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Home from "./pages/Home";
import PrivateRoute from "./PrivateRoute";
import China from "./pages/China";
import CustomerService from "./pages/CustomerService";
import Admin from "./pages/Admin";
import Register from "./pages/Register";
import Shipment from "./pages/Shipment";
import Summary from "./pages/Summary";
import Setting from "./pages/Setting";
import Finance from "./pages/Finance";
import Delivery from "./pages/Delivery";
import DeliveryAdmin from "./pages/DeliveryAdmin";
import Attendance from "./pages/Attendance";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<PrivateRoute element={<Register />} />} />
        <Route path="/" element={<PrivateRoute element={<Home />} />} />
        <Route path="/china" element={<PrivateRoute element={<China />} roles={["China", "Admin"]} />} />
        <Route path="/customerService" element={<PrivateRoute element={<CustomerService />} roles={["CustomerService", "Admin"]} />} />
        <Route path="/shipment" element={<PrivateRoute element={<Shipment />} roles={["Shipment", "Admin"]} />} />
        <Route path="/admin" element={<PrivateRoute element={<Admin />} roles={["Admin"]} />} />
        <Route path="/summary" element={<PrivateRoute element={<Summary />} roles={["User", "Admin"]} />} />
        <Route path="/setting" element={<PrivateRoute element={<Setting />} roles={["Admin"]} />} />
        <Route path="/finance" element={<PrivateRoute element={<Finance />} roles={["Finance", "Admin"]} />} />
        <Route path="/delivery" element={<PrivateRoute element={<Delivery />} roles={["Delivery", "Admin"]} />} />
        <Route path="/deliveryAdmin" element={<PrivateRoute element={<DeliveryAdmin />} roles={["DeliveryAdmin", "Admin"]} />} />
        <Route path="/attendance" element={<PrivateRoute element={<Attendance />} roles={["Admin"]} />} />
      </Routes>
    </Router>
  );
}

export default App;
