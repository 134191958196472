import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import FinanceTable from "./FinanceTable";

const apiUrl = process.env.REACT_APP_API_URL;

function OthersTable() {
  const [totalPrice, setTotalPrice] = useState("");
  const [filterMonth, setFilterMonth] = useState("");
  const [filterDay, setFilterDay] = useState("");
  const [filterStatusP, setFilterStatusP] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);
  const [otherData, setOtherData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [totalOrder, setTotalOrder] = useState("");

  const dropdownRef = useRef(null);
  const monthToNumber = (month) => {
    const months = {
      JAN: 1,
      FEB: 2,
      MAR: 3,
      APR: 4,
      MAY: 5,
      JUN: 6,
      JUL: 7,
      AUG: 8,
      SEP: 9,
      OCT: 10,
      NOV: 11,
      DEC: 12,
    };
    return months[month] || 0;
  };
  const monthNames = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const parseMonthFromOrderNumber = (orderNumber) => {
    const monthPart = orderNumber.startsWith("OP")
      ? orderNumber.slice(2, 5)
      : orderNumber.slice(0, 3);
    return monthPart.toUpperCase();
  };

  const sortByOrderNumber = (data) => {
    return data.sort((a, b) => {
      const [aMonth, aNumber] = [
        parseMonthFromOrderNumber(a.OrderNumber),
        parseInt(a.OrderNumber.slice(-2)),
      ];
      const [bMonth, bNumber] = [
        parseMonthFromOrderNumber(b.OrderNumber),
        parseInt(b.OrderNumber.slice(-2)),
      ];
      const aMonthNum = monthToNumber(aMonth);
      const bMonthNum = monthToNumber(bMonth);

      if (aMonthNum !== bMonthNum) {
        return bMonthNum - aMonthNum; // Sort by month first (descending)
      }
      return bNumber - aNumber; // Then by number (descending)
    });
  };
  const filterDataByMonth = (data, month) => {
    return data.filter(
      (item) => parseMonthFromOrderNumber(item.OrderNumber) === month
    );
  };

  const currentMonthShortForm = () => {
    const monthIndex = new Date().getMonth(); // Get current month index (0-11)
    return monthNames[monthIndex];
  };
  const [selectedMonth, setSelectedMonth] = useState(currentMonthShortForm());

  const getCurrentMonthShortForm = () => {
    const monthIndex = new Date().getMonth();
    return months[monthIndex];
  };

  // Filter data by month and calculate totals
  const filterByMonthAll = (month = getCurrentMonthShortForm()) => {
    setSelectedMonth(month);
    // const filtered = data.filter((item) => item.OrderNumber.startsWith(month));
    const filtered = data.filter((item) =>
      item.OrderNumber.startsWith(`OP${month}`)
  );
  setFilteredData(filtered);
    const sum = filtered.reduce(
      (acc, item) => acc + parseFloat(item.Price || 0),
      0
    );
    const ordered = filtered.length;

    setTotalPrice(sum);
    setTotalOrder(ordered);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/otherItems`);
      const fetchedData = response.data.map((item) => ({
        ...item,
        imageUrl1: `${apiUrl}/uploads/${item.ImageFilename1}`, // Construct the first image URL
        imageUrl2: `${apiUrl}/uploads/${item.ImageFilename2}`, // Construct the image URL
      }));
      const sortedData = sortByOrderNumber(fetchedData);
      setData(sortedData);
      setFilteredData(filterDataByMonth(sortedData, selectedMonth)); // Filter data by current month
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  const updateStatus = async (itemId, status) => {
    try {
      setLoading(true);
      await axios.put(`${apiUrl}/updateStatus`, { id: itemId, status });
      setLoading(false);
      // Fetch updated data after successful status update
      fetchData();
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  const handleOptionSelect = (index, itemId, status) => {
    setDropdownOpen(null); // Close the dropdown

    // Update the status in the frontend optimistically
    setData(
      data.map((item) =>
        item.ID === itemId ? { ...item, Status: status } : item
      )
    );

    // Update the status in the backend
    updateStatus(itemId, status);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const toggleDropdown = (index) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Adjust this to your preference

  const [initialData, setInitialData] = useState({}); // Store the initial row data when editing starts

  const [isEditing, setIsEditing] = useState(null);
  const [editData, setEditData] = useState({
    brand: "",
    model: "",
    sim: "",
    ram: "",
    storage: "",
    status: "",
    delivery: "",
    price: "",
  });

  const handleEditClick = (item) => {
    setIsEditing(item.ID);
    setInitialData({
      brand: item.Brand,
      model: item.Model,
      sim: item.Sim,
      ram: item.Ram,
      storage: item.Storage,
      status: item.Status,
      delivery: item.DeliveryFee,
      price: item.Price,
    });
    setEditData({}); // Start with an empty object
  };

  const handleChange = (field, value) => {
    setEditData((prev) => ({
      ...prev,
      [field]: value !== initialData[field] ? value : undefined, // Set value if changed, otherwise undefined
    }));
  };

  // Function to handle saving the edited data
  const handleSave = async (id) => {
    console.log("ini", initialData);
    const completeEditData = {
      model: editData.model !== undefined ? editData.model : initialData.model,
      price: editData.price !== undefined ? editData.price : initialData.price,
      status:
        editData.status !== undefined ? editData.status : initialData.status,
      delivery:
        editData.delivery !== undefined
          ? editData.delivery
          : initialData.delivery,
    };

    try {
      setLoading(true);
      const response = await axios.put(
        `${apiUrl}/updateOtherItem/${id}`,
        completeEditData
      );
      setData(data.map((item) => (item.ID === id ? response.data : item)));
      setIsEditing(null);
      setLoading(false);
      fetchData();
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  const filterByMonth = (data, month) => {
    if (!month) return data;
    return data.filter((item) => {
      const itemMonth = new Date(item.TimeStamp).getMonth() + 1; // getMonth is 0-indexed
      return itemMonth === parseInt(month);
    });
  };

  const filterByDay = (data, day, month) => {
    if (!day || !month) return data; // Ensure month is selected
    return data.filter((item) => {
      const itemDate = new Date(item.TimeStamp);
      const itemMonth = itemDate.getMonth() + 1;
      const itemDay = itemDate.getDate();
      return itemMonth === parseInt(month) && itemDay === parseInt(day); // Filter by day within the selected month
    });
  };

  const filterStatus = (data, status) => {
    if (!status) return data;
    return data.filter((item) => item.Status === status); // Filter by status
  };

  // Calculate the number of days for the selected month
  const getDaysInMonth = (month) => {
    const year = new Date().getFullYear(); // Use the current year
    return new Date(year, month, 0).getDate(); // Get the last day of the month
  };

  const handleClear = () => {
    setFilterMonth("");
    setFilterDay("");
    setFilterStatusP("");
  };

  const handleMonthSelect = (month) => {
    setSelectedMonth(month);
    filterByMonthAll(month); // Hide the dropdown after selection
  };
  const [isMobile, setIsMobile] = useState(false);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // Mobile if width <= 768px
  };

  useEffect(() => {
    handleResize(); // Check initially
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className=" border-1 border-slate-200 mt-20 w-3/4 mb-20 h-screen px-5 rounded-md">
        <div className="flex flex-col my-2">
          <h1 className="text-3xl font-bold text-gray-800">Other Products</h1>
        </div>
        {isMobile ? (
          <div className="flex flex-col my-2">
            <select
              className="border p-2 mr-2"
              value={selectedMonth}
              onChange={(e) => handleMonthSelect(e.target.value)}
            >
              <option value="" disabled>
                Select Month
              </option>
              {months.map((month) => (
                <option
                  key={month}
                  value={month}
                  onClick={() => filterByMonthAll(month)}
                >
                  {month}
                </option>
              ))}
            </select>
            <select
              name="filterDay"
              id="filterDay"
              className="border p-2 mr-2 my-2"
              value={filterDay}
              onChange={(e) => setFilterDay(e.target.value)}
              disabled={!filterMonth} // Disable the day filter if no month is selected
            >
              <option value="" disabled>
                Filter by Day
              </option>
              {filterMonth &&
                Array.from(
                  { length: getDaysInMonth(filterMonth) },
                  (_, i) => i + 1
                ).map((day) => (
                  <option key={day} value={day}>
                    {day}
                  </option>
                ))}
            </select>

            <select
              name="filterStatusP"
              id="filterStatusP"
              className="border p-2 mr-2 mb-2"
              value={filterStatusP}
              onChange={(e) => setFilterStatusP(e.target.value)}
            >
              <option value="" disabled>
                Filter by Status
              </option>
              <option value="Ordered">Ordered</option>
              <option value="Received">Received</option>
              <option value="Sent">Sent</option>
              <option value="Return Received">Return Received</option>
              <option value="Return Sent">Return Sent</option>
            </select>

            <button
              onClick={handleClear}
              className="bg-slate-600 hover:bg-slate-500 text-white font-bold px-4 mb-2 rounded"
            >
              Clear
            </button>
          </div>
        ) : (
          <div className="flex flex-row my-2">
            <select
              className="border p-2 mr-2"
              value={selectedMonth}
              onChange={(e) => handleMonthSelect(e.target.value)}
            >
              <option value="" disabled>
                Select Month
              </option>
              {months.map((month) => (
                <option
                  key={month}
                  value={month}
                  onClick={() => filterByMonthAll(month)}
                >
                  {month}
                </option>
              ))}
            </select>
            <select
              name="filterDay"
              id="filterDay"
              className="border p-2 mr-2"
              value={filterDay}
              onChange={(e) => setFilterDay(e.target.value)}
              disabled={!filterMonth} // Disable the day filter if no month is selected
            >
              <option value="" disabled>
                Filter by Day
              </option>
              {filterMonth &&
                Array.from(
                  { length: getDaysInMonth(filterMonth) },
                  (_, i) => i + 1
                ).map((day) => (
                  <option key={day} value={day}>
                    {day}
                  </option>
                ))}
            </select>

            <select
              name="filterStatusP"
              id="filterStatusP"
              className="border p-2 mr-2"
              value={filterStatusP}
              onChange={(e) => setFilterStatusP(e.target.value)}
            >
              <option value="" disabled>
                Filter by Status
              </option>
              <option value="Ordered">Ordered</option>
              <option value="Received">Received</option>
              <option value="Sent">Sent</option>
              <option value="Return Received">Return Received</option>
              <option value="Return Sent">Return Sent</option>
            </select>

            <button
              onClick={handleClear}
              className="bg-slate-600 hover:bg-slate-500 text-white font-bold px-4 rounded"
            >
              Clear
            </button>
          </div>
        )}
        {/* Conditionally render the select for mobile screens */}
        {isMobile ? (
          <select
            className="border p-2 mr-2"
            value={selectedMonth}
            onChange={(e) => handleMonthSelect(e.target.value)}
          >
            <option value="" disabled>
              Select Month
            </option>
            {months.map((month) => (
              <option
                key={month}
                value={month}
                onClick={() => filterByMonthAll(month)}
              >
                {month}
              </option>
            ))}
          </select>
        ) : (
          ""
        )}
        <div className="relative max-h-[80vh] overflow-y-auto custom-scrollbar">
          <table className="w-full border-collapse">
            <thead className="sticky top-0 z-10 border-b border-slate-600 text-left bg-white">
              <tr>
                <th className="sticky top-0 z-10 border-b border-slate-600 text-left  bg-gray-100">
                  #
                </th>
                <th className="sticky top-0 z-10 border-b border-slate-600 text-left  bg-gray-100">
                  ItemName
                </th>
                <th className="sticky top-0 z-10 border-b border-slate-600 text-left  bg-gray-100">
                  Price
                </th>{" "}
                {/* Custom color */}
                <th className="sticky top-0 z-10 border-b border-slate-600 text-left  bg-gray-200">
                  Delivery Fee
                </th>
                <th className="sticky top-0 z-10 border-b border-slate-600 text-left  bg-gray-200">
                  Status
                </th>
                <th className="sticky top-0 z-10 border-b border-slate-600 text-left  bg-gray-100">
                  Delivery Status
                </th>
                <th className="sticky top-0 z-10 border-b border-slate-600 text-left  bg-gray-200">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => (
                <tr key={item.ID} className=" border-b-1 border-slate-600">
                  {isEditing === item.ID ? (
                    <>
                      <td className="text-left m-0">{item.OrderNumber}</td>
                      <td className="text-left m-0">
                        <input
                          className="border w-full"
                          type="text"
                          value={
                            editData.model !== undefined
                              ? editData.model
                              : item.ItemName
                          }
                          onChange={(e) =>
                            handleChange("model", e.target.value)
                          }
                        />
                      </td>
                      <td className="text-left m-0">
                        <input
                          className="border w-full"
                          type="text"
                          value={
                            editData.price !== undefined
                              ? editData.price
                              : item.price
                          }
                          onChange={(e) =>
                            handleChange("price", e.target.value)
                          }
                        />
                      </td>
                      <td className="text-left m-0">
                        <input
                          className="border w-full"
                          type="text"
                          value={
                            editData.delivery !== undefined
                              ? editData.delivery
                              : item.Delivery
                          }
                          onChange={(e) =>
                            handleChange("delivery", e.target.value)
                          }
                        />
                      </td>
                      <td className="text-left m-0">
                        <div className="relative inline-block text-left">
                          <div className="flex flex-row justify-around">
                            <p className="inline-flex justify-around gap-x-1.5 rounded-md bg-gray-100  w-20 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-gray-50">
                              {item.Status || "Options"}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="text-left m-0">
                        <div className="relative inline-block text-left">
                          <div className="flex flex-row justify-around">
                            <p className="inline-flex justify-around gap-x-1.5 rounded-md bg-gray-100  w-20 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-gray-50">
                              {item.Status || "Options"}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="flex flex-row text-left m-0">
                        <button
                          className="inline-flex justify-around gap-x-1.5 rounded-md bg-gray-100  w-12 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-gray-50"
                          onClick={() => handleSave(item.ID)}
                        >
                          Save
                        </button>
                        <button
                          className="inline-flex justify-around gap-x-1.5 rounded-md bg-red-500  w-12 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-red-300"
                          onClick={() => setIsEditing(null)}
                        >
                          Cancel
                        </button>
                      </td>
                    </>
                  ) : (
                    <>
                      <td className="  text-left font-bold text-slate-800 m-0 bg-gray-100">
                        {item.OrderNumber}
                      </td>
                      <td className="text-left m-0 bg-gray-100">
                        {item.ItemName}
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                        {item.price}
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left  m-0 bg-gray-200">
                        {item.Delivery}
                      </td>

                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                        <div className="relative inline-block text-left">
                          <div className="flex flex-row justify-around">
                            <button
                              type="button"
                              className="inline-flex justify-around gap-x-1.5 rounded-md bg-gray-300  w-20 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-gray-50"
                              id="menu-button"
                              aria-expanded={dropdownOpen === index}
                              aria-haspopup="true"
                              onClick={() => toggleDropdown(index)}
                            >
                              {item.Status || "Options"}
                              <svg
                                className="-mr-1 h-5 w-5 text-gray-400"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                          </div>
                          {dropdownOpen === index && (
                            <div
                              className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                              aria-orientation="vertical"
                              aria-labelledby="menu-button"
                              tabIndex="-1"
                            >
                              <div className="py-1">
                                <button
                                  className="block w-full px-4 py-2 text-left text-sm text-gray-700 "
                                  role="menuitem"
                                  tabIndex="-1"
                                  onClick={() =>
                                    handleOptionSelect(
                                      index,
                                      item.ID,
                                      "Ordered"
                                    )
                                  }
                                >
                                  Ordered
                                </button>
                                <button
                                  className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                  role="menuitem"
                                  tabIndex="-1"
                                  onClick={() =>
                                    handleOptionSelect(
                                      index,
                                      item.ID,
                                      "Received"
                                    )
                                  }
                                >
                                  Received
                                </button>
                                <button
                                  className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                  role="menuitem"
                                  tabIndex="-1"
                                  onClick={() =>
                                    handleOptionSelect(index, item.ID, "Sent")
                                  }
                                >
                                  Sent
                                </button>
                                <button
                                  className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                  role="menuitem"
                                  tabIndex="-1"
                                  onClick={() =>
                                    handleOptionSelect(index, item.ID, "Ren")
                                  }
                                >
                                  Returned
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                        <div className="relative inline-block text-left">
                          <div className="flex flex-row justify-around">
                            <p className="inline-flex justify-around gap-x-1.5 rounded-md bg-slate-500 text-white w-20 mr-1 py-2 text-sm font-semibold  shadow-sm">
                              {item.Status || "Options"}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left  m-0 bg-gray-200">
                        <button
                          className="inline-flex justify-around gap-x-1.5 rounded-md bg-gray-700 w-12 mr-1 py-2 text-sm font-semibold  shadow-sm  hover:bg-gray-500 text-white"
                          onClick={() => handleEditClick(item)}
                        >
                          Edit
                        </button>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <FinanceTable />
    </>
  );
}

export default OthersTable;
