import React, { useState, useEffect } from "react";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

function EtTable() {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const monthToNumber = (month) => {
    const months = {
      JAN: 1,
      FEB: 2,
      MAR: 3,
      APR: 4,
      MAY: 5,
      JUN: 6,
      JUL: 7,
      AUG: 8,
      SEP: 9,
      OCT: 10,
      NOV: 11,
      DEC: 12,
    };
    return months[month] || 0;
  };

  const sortByOrderNumber = (data) => {
    return data.sort((a, b) => {
      const [aMonth, aNumber] = [
        a.OrderNumber.slice(0, 3),
        parseInt(a.OrderNumber.slice(3)),
      ];
      const [bMonth, bNumber] = [
        b.OrderNumber.slice(0, 3),
        parseInt(b.OrderNumber.slice(3)),
      ];

      const aMonthNum = monthToNumber(aMonth);
      const bMonthNum = monthToNumber(bMonth);

      if (aMonthNum !== bMonthNum) {
        return bMonthNum - aMonthNum; // Sort by month first (descending)
      }
      return bNumber - aNumber; // Then by the number (descending)
    });
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/unsoldItems`);
      const fetchedData = response.data.map((item) => ({
        ...item,
        imageUrl1: `${apiUrl}/uploads/${item.ImageFilename1}`, // Construct the first image URL
        imageUrl2: `${apiUrl}/uploads/${item.ImageFilename2}`, // Construct the image URL
      }));
      const sortedData = sortByOrderNumber(fetchedData);

      setData(sortedData); // Set the sorted data
      setFilteredData(sortedData); // Initialize filteredData with all products
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="items-center flex flex-col justify-center mt-5 overflow-auto">
      <div className=" border-1 border-slate-200 mt-20 w-3/4 mb-20 px-5 rounded-md overflow-auto justify-center align-middle">
        <h1 className="font-bold text-2xl my-3">Unsold</h1>
        <table className="border-collapse table-auto w-full mb-10 overflow-x-scroll">
          <thead>
            <tr>
              <th className="border-b-1 pb-3 border-slate-600 text-left bg-gray-200">#</th>
              <th className="border-b-1 pb-3 border-slate-600 text-left bg-gray-300">
                Brand
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left bg-gray-200">
                Model
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-300">
                Storage
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                Ram
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-300">
                Sim
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                Price
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-300">
                Delivery Fee
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                Total Price
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-300">
                ET Status
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => {
              // Calculate total price for the current row
              const totalPriceForRow = () => {
                const price = Number(item.Price) || 0;
                const com1 = Number(item.com1) || 70;
                const com2 = Number(item.com2) || 40;
                const deliveryFee = Number(item.DeliveryFee) || 0;
                return price + com1 + com2 + deliveryFee;
              };

              return (
                <tr key={index}>
                  <td className="border-b-1 pb-3 border-slate-600 text-left font-bold text-slate-800 m-0 bg-gray-100">
                    {item.OrderNumber}
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                    {item.Brand}
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                    {item.Model}
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                    {item.Storage}
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                    {item.Ram}
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                    {item.Sim}
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                    {item.Price}
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                    {item.DeliveryFee}
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                    {totalPriceForRow()} {/* Show total for each row */}
                  </td>
                  <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                    {item.ourStat}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="flex flex-row">
          <h1 className="text-2xl font-bold mr-2">Total Remaining: </h1>
          <p className="text-2xl underline"> {filteredData.length}</p>
        </div>
      </div>
    </div>
  );
}

export default EtTable;
