import React, { useEffect, useState } from "react";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

function TotalSent() {
  const [filtegreenData, setFiltegreenData] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const currentMonthShortForm = () => {
    const monthIndex = new Date().getMonth(); // Get current month index (0-11)
    const monthNames = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    return monthNames[monthIndex];
  };

  const monthToNumber = (month) => {
    const months = {
      JAN: 1,
      FEB: 2,
      MAR: 3,
      APR: 4,
      MAY: 5,
      JUN: 6,
      JUL: 7,
      AUG: 8,
      SEP: 9,
      OCT: 10,
      NOV: 11,
      DEC: 12,
    };
    return months[month] || 0;
  };

  const [selectedMonth, setSelectedMonth] = useState(currentMonthShortForm()); // Initialize to current month

  const filterDataByMonth = (data, month) => {
    const mon = monthToNumber(month); // Convert month name to number (e.g., "November" -> 11)

    const filteredData = data.filter((item) => {
      const itemMonth = new Date(item.date).getMonth() + 1; // Extract the month from the date (0-indexed, so add 1)
      return itemMonth === mon; // Compare extracted month with the given month
    });

    return filteredData;
  };

  const handleMonthChange = (event) => {
    const month = event.target.value;
    setSelectedMonth(month);
    setFiltegreenData(filterDataByMonth(data, month));
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/finance`);
      const fetchedData = response.data.map((item) => ({
        ...item,
      }));
      setData(fetchedData);
      setFiltegreenData(filterDataByMonth(fetchedData, selectedMonth));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occur");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Calculate the number of days for the selected month

  const totalBirr = filtegreenData.reduce(
    (acc, item) => acc + Number(item.birr || 0),
    0
  );

  const totalYuan = filtegreenData.reduce(
    (acc, item) => acc + Number(item.yuan || 0),
    0
  );

  return (
    <div className="items-center flex flex-col justify-center mt-5 overflow-auto w-full">
      <div className="border-1 border-slate-500  w-3/4 mb-20 px-10 rounded-md overflow-auto">
        <div className="my-2">
          <label htmlFor="month-select" className="font-bold mr-2">
            Select Month:
          </label>
          <select
            id="month-select"
            onChange={handleMonthChange}
            value={selectedMonth}
          >
            <option value="">All</option>
            <option value="JAN">January</option>
            <option value="FEB">February</option>
            <option value="MAR">March</option>
            <option value="APR">April</option>
            <option value="MAY">May</option>
            <option value="JUN">June</option>
            <option value="JUL">July</option>
            <option value="AUG">August</option>
            <option value="SEP">September</option>
            <option value="OCT">October</option>
            <option value="NOV">November</option>
            <option value="DEC">December</option>
          </select>
        </div>
        <table className="border-collapse table-auto w-full mb-10 overflow-x-scroll">
          <thead>
            <tr>
              <th className="border-b-1 pb-3 border-slate-600 text-left bg-green-100">
                Total birr
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left bg-green-100">
                Total Sent
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-b-1 border-slate-600">
              <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-green-50 font-bold">
                {totalBirr}
              </td>
              <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-green-50 font-bold">
                {totalYuan}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TotalSent;
