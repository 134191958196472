import Header from "../component/Header";
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import noImage from "../images/errors.png";
import { Oval } from "react-loader-spinner";

const apiUrl = process.env.REACT_APP_API_URL;

function CustomerService() {
  const [editImage, setEditImage] = useState(null); // Define state for the image

  const [totalPrice, setTotalPrice] = useState("");
  const [filterMonth, setFilterMonth] = useState("");
  const [filterDay, setFilterDay] = useState("");
  const [filterStatusP, setFilterStatusP] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [imageFile1, setImageFile1] = useState(null); // For the first image
  const [imageFile2, setImageFile2] = useState(null); // For the second image
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [totalSent, setTotalSent] = useState("");
  const [totalReceivedCal, setTotalReceivedCal] = useState("");
  const [totalOrder, setTotalOrder] = useState("");
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Added for year filter

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Get current month in short form
  const getCurrentMonthShortForm = () => {
    const monthIndex = new Date().getMonth();
    return months[monthIndex];
  };

  const sortByOrderNumber = (data) => {
    return data.sort((a, b) => {
      const [aMonth, aNumber] = [
        a.OrderNumber.slice(0, 3),
        parseInt(a.OrderNumber.slice(3), 10),
      ];
      const [bMonth, bNumber] = [
        b.OrderNumber.slice(0, 3),
        parseInt(b.OrderNumber.slice(3), 10),
      ];
      return aMonth !== bMonth
        ? bMonth.localeCompare(aMonth)
        : aNumber - bNumber;
    });
  };

  const filterDataByMonth = (data, month) => {
    return data.filter((item) => item.OrderNumber.startsWith(month));
  };

  const filterDataByYear = (data, year) => {
    return data.filter((item) => {
      const itemYear = new Date(item.TimeStamp).getFullYear();
      return itemYear === parseInt(year, 10);
    });
  };

  const applyFilters = (data, month, year) => {
    let filtered = filterDataByYear(data, year);
    filtered = filterDataByMonth(filtered, month);
    return filtered;
  };

  const currentMonthShortForm = () => {
    const monthIndex = new Date().getMonth(); // Get current month index (0-11)
    return months[monthIndex];
  };
  const [selectedMonth, setSelectedMonth] = useState(currentMonthShortForm());

  const filterByMonthAll = (month = getCurrentMonthShortForm()) => {
    setSelectedMonth(month);
    const filteredMonth = data.filter((item) =>
      item.OrderNumber.startsWith(month)
    );
    const filteredYear = filterDataByYear(filteredMonth, selectedYear);
    setFilteredData(filteredYear);

    const sum = filteredMonth.reduce(
      (acc, item) => acc + parseFloat(item.Price || 0),
      0
    );
    const receivedTotal = filteredMonth.filter(
      (item) => item.Status === "Received"
    ).length;
    const sentTotal = filteredMonth.filter(
      (item) => item.Status === "Sent"
    ).length;
    const ordered = filteredMonth.length;

    setTotalPrice(sum);
    setTotalReceivedCal(receivedTotal);
    setTotalSent(sentTotal);
    setTotalOrder(ordered);
  };

  const filterByMonthAndYear = (
    month = currentMonthShortForm(),
    year = new Date().getFullYear()
  ) => {
    setSelectedMonth(month);
    setSelectedYear(year);
    const filtered = applyFilters(data, month, year);
    setFilteredData(filtered);

    const sum = filtered.reduce(
      (acc, item) => acc + parseFloat(item.Price || 0),
      0
    );
    const receivedTotal = filtered.filter(
      (item) => item.Status === "Received"
    ).length;
    const sentTotal = filtered.filter((item) => item.Status === "Sent").length;
    const ordered = filtered.length;

    setTotalPrice(sum);
    setTotalReceivedCal(receivedTotal);
    setTotalSent(sentTotal);
    setTotalOrder(ordered);
  };

  const handleYearChange = (event) => {
    const year = event.target.value;
    filterByMonthAndYear(selectedMonth, year);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/items`);
      const fetchedData = response.data.map((item) => ({
        ...item,
        imageUrl1: `${apiUrl}/uploads/${item.ImageFilename1}`, // Construct the first image URL
        imageUrl2: `${apiUrl}/uploads/${item.ImageFilename2}`, // Construct the image URL
      }));
      const sortedData = sortByOrderNumber(fetchedData);

      setData(sortedData); // Set the sorted data
      setFilteredData(filterDataByMonth(sortedData, selectedMonth)); // Initialize filteredData with all products
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  const updateStatus = async (itemId, ourStat) => {
    try {
      setLoading(true);
      await axios.put(`${apiUrl}/updateOurStat`, { id: itemId, ourStat });
      setLoading(false);
      // Fetch updated data after successful status update
      fetchData();
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  const updateSeller = async (itemId, seller) => {
    try {
      setLoading(true);
      await axios.put(`${apiUrl}/updateSeller`, { id: itemId, seller });
      setLoading(false);
      // Fetch updated data after successful status update
      fetchData();
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  const updateReceiver = async (itemId, receiver) => {
    try {
      setLoading(true);
      await axios.put(`${apiUrl}/updateReceiver`, { id: itemId, receiver });
      setLoading(false);
      // Fetch updated data after successful status update
      fetchData();
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  const handleOptionSelect = (index, itemId, ourStat) => {
    setDropdownOpenOurStat(null); // Close the dropdown
    // Update the status in the frontend optimistically
    setData(
      data.map((item) =>
        item.ID === itemId ? { ...item, ourStat: ourStat } : item
      )
    );
    // Update the status in the backend
    updateStatus(itemId, ourStat);
  };

  const handleOptionSelectSeller = (index, itemId, seller) => {
    setDropdownOpenSeller(null); // Close the dropdown
    // Update the status in the frontend optimistically
    setData(
      data.map((item) =>
        item.ID === itemId ? { ...item, seller: seller } : item
      )
    );
    // Update the status in the backend
    updateSeller(itemId, seller);
  };

  const handleOptionSelectReceiver = (index, itemId, receiver) => {
    setDropdownOpenReceiver(null); // Close the dropdown
    // Update the status in the frontend optimistically
    setData(
      data.map((item) =>
        item.ID === itemId ? { ...item, seller: receiver } : item
      )
    );
    // Update the status in the backend
    updateReceiver(itemId, receiver);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [dropdownOpenSeller, setDropdownOpenSeller] = useState(null);
  const [dropdownOpenOurStat, setDropdownOpenOurStat] = useState(null);
  const [dropdownOpenReceiver, setDropdownOpenReceiver] = useState(null);

  // Separate refs for each dropdown
  const dropdownRefSeller = useRef(null);
  const dropdownRefOurStat = useRef(null);
  const dropdownRefReceiver = useRef(null);

  const toggleDropdownSeller = (index) => {
    setDropdownOpenSeller(dropdownOpenSeller === index ? null : index);
  };

  const toggleDropdownOurStat = (index) => {
    setDropdownOpenOurStat(dropdownOpenOurStat === index ? null : index);
  };

  const toggleDropdownReceiver = (index) => {
    setDropdownOpenReceiver(dropdownOpenReceiver === index ? null : index);
  };

  // Handle clicking outside for all dropdowns
  const handleClickOutside = (event) => {
    if (
      dropdownRefSeller.current &&
      !dropdownRefSeller.current.contains(event.target)
    ) {
      setDropdownOpenSeller(null);
    }
    if (
      dropdownRefOurStat.current &&
      !dropdownRefOurStat.current.contains(event.target)
    ) {
      setDropdownOpenOurStat(null);
    }
    if (
      dropdownRefReceiver.current &&
      !dropdownRefReceiver.current.contains(event.target)
    ) {
      setDropdownOpenReceiver(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [initialData, setInitialData] = useState({}); // Store the initial row data when editing starts

  const [isEditing, setIsEditing] = useState(null);
  const [editData, setEditData] = useState({
    status: "",
    delivery: "",
    price: "",
    buyer: "",
    seller: "",
    phone: "",
    salesDate: "",
    soldPrice: "",
    rate: "",
  });

  const handleEditClick = (item) => {
    setIsEditing(item.ID);
    setInitialData({
      status: item.Status,
      delivery: item.DeliveryFee,
      price: item.Price, // Add image URL to initial data
      buyer: item.buyer, // Add image URL to initial data
      seller: item.seller, // Add image URL to initial data
      phone: item.phone, // Add image URL to initial data
      soldPrice: item.soldPrice, // Add image URL to initial data
      rate: item.rate, // Add image URL to initial data
      salesDate: item.salesDate, // Add image URL to initial data
    });
    setEditData({}); // Start with an empty object
    setImageFile1(null);
    setImageFile1(null); // Start with no image
  };
  const [editImage1, setEditImage1] = useState(null);
  const [editImage2, setEditImage2] = useState(null);

  // Function to handle saving the edited data
  const handleSave = async (id) => {
    try {
      setLoading(true);
      const userId = localStorage.getItem("token");
      const completeEditData = new FormData();

      completeEditData.append(
        "buyer",
        editData.buyer !== undefined ? editData.buyer : initialData.buyer
      );
      completeEditData.append(
        "seller",
        editData.seller !== undefined ? editData.seller : initialData.seller
      );
      completeEditData.append(
        "phone",
        editData.phone !== undefined ? editData.phone : initialData.phone
      );
      completeEditData.append(
        "rate",
        editData.rate !== undefined ? editData.rate : initialData.rate
      );
      completeEditData.append(
        "salesDate",
        editData.salesDate !== undefined
          ? editData.salesDate
          : initialData.salesDate
      );
      completeEditData.append(
        "soldPrice",
        editData.soldPrice !== undefined
          ? editData.soldPrice
          : initialData.soldPrice
      );

      // Append image files if they are selected
      if (editImage1) {
        completeEditData.append("images", editImage1); // First image
      }
      if (editImage2) {
        completeEditData.append("images", editImage2); // Second image
      }

      // Make the PUT request
      const response = await axios.put(
        `${apiUrl}/updateItemCustomer/${id}`,
        completeEditData,
        {
          headers: {
            Authorization: `Bearer ${userId}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setData(data.map((item) => (item.ID === id ? response.data : item)));
      setIsEditing(null);
      setLoading(false);
      setEditImage1(null);
      setEditImage2(null);
      fetchData();
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  const filterByMonth = (data, month) => {
    if (!month) return data;
    return data.filter((item) => {
      const itemMonth = new Date(item.TimeStamp).getMonth() + 1; // getMonth is 0-indexed
      return itemMonth === parseInt(month);
    });
  };

  const filterByDay = (data, day, month) => {
    if (!day || !month) return data; // Ensure month is selected
    return data.filter((item) => {
      const itemDate = new Date(item.TimeStamp);
      const itemMonth = itemDate.getMonth() + 1;
      const itemDay = itemDate.getDate();
      return itemMonth === parseInt(month) && itemDay === parseInt(day); // Filter by day within the selected month
    });
  };

  const filterStatus = (data, status) => {
    if (!status) return data;
    return data.filter((item) => item.ourStat === status); // Filter by status
  };

  // Update useEffect to handle filtering
  // useEffect(() => {
  //   let filtered = data;
  //   if (filterMonth) {
  //     filtered = filterByMonth(filtered, filterMonth);
  //   }
  //   if (filterDay && filterMonth) {
  //     // Only filter by day if month is selected
  //     filtered = filterByDay(filtered, filterDay, filterMonth);
  //   }
  //   if (filterStatusP) {
  //     filtered = filterStatus(filtered, filterStatusP);
  //   }
  //   setFilteredData(filtered);
  // }, [data, filterMonth, filterDay, filterStatusP]);

  // Calculate the number of days for the selected month
  const getDaysInMonth = (month) => {
    const year = new Date().getFullYear(); // Use the current year
    return new Date(year, month, 0).getDate(); // Get the last day of the month
  };

  const handleClear = () => {
    setFilterMonth("");
    setFilterDay("");
    setFilterStatusP("");
  };

  const handleMonthSelect = (month) => {
    setSelectedMonth(month);
    filterByMonthAll(month); // Hide the dropdown after selection
  };

  const [isMobile, setIsMobile] = useState(false);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // Mobile if width <= 768px
  };

  useEffect(() => {
    handleResize(); // Check initially
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleChange = (field, value) => {
    setEditData((prev) => ({
      ...prev,
      [field]: value !== initialData[field] ? value : undefined, // Set value if changed, otherwise undefined
    }));
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");

    return `${day}-${month}-${year}`; // Format as 'YYYY-MM-DD'
  };

  return (
    <div>
      <Header />
      <div className="items-center flex flex-col justify-center mt-5 overflow-auto">
        {loading ? <p>Loading</p> : null}
        {errors ? (
          <p className="bg-red-500 text-zinc-100 rounded px-5 py-2">{errors}</p>
        ) : null}
        <div className=" border-1 border-slate-200 mt-20 w-3/4 mb-20 px-5 rounded-md overflow-auto h-screen">
          <div className="flex flex-col my-2">
            <h1 className="text-3xl font-bold text-gray-800">Products</h1>
          </div>
          {isMobile ? (
            <div className="flex flex-col my-2">
              <select
                className="border p-2 mr-2"
                value={selectedMonth}
                onChange={(e) => handleMonthSelect(e.target.value)}
              >
                <option value="" disabled>
                  Select Month
                </option>
                {months.map((month) => (
                  <option
                    key={month}
                    value={month}
                    onClick={() => filterByMonthAll(month)}
                  >
                    {month}
                  </option>
                ))}
              </select>
              <select
                name="filterDay"
                id="filterDay"
                className="border p-2 mr-2 my-2"
                value={filterDay}
                onChange={(e) => setFilterDay(e.target.value)}
                disabled={!filterMonth} // Disable the day filter if no month is selected
              >
                <option value="" disabled>
                  Filter by Day
                </option>
                {filterMonth &&
                  Array.from(
                    { length: getDaysInMonth(filterMonth) },
                    (_, i) => i + 1
                  ).map((day) => (
                    <option key={day} value={day}>
                      {day}
                    </option>
                  ))}
              </select>

              <select
                name="filterStatusP"
                id="filterStatusP"
                className="border p-2 mr-2 mb-2"
                value={filterStatusP}
                onChange={(e) => setFilterStatusP(e.target.value)}
              >
                <option value="" disabled>
                  Filter by Status
                </option>
                <option value="Ordered">Ordered</option>
                <option value="Received">Received</option>
                <option value="Sent">Sent</option>
                <option value="Return Received">Return Received</option>
                <option value="Return Sent">Return Sent</option>
              </select>
              <label htmlFor="year-select" className="mx-3 font-bold">
                Year:
              </label>
              <input
                id="year-select"
                type="number"
                value={selectedYear}
                onChange={handleYearChange}
                min="2000"
                max={new Date().getFullYear()}
              />
              <button
                onClick={handleClear}
                className="bg-slate-600 hover:bg-slate-500 text-white font-bold px-4 mb-2 rounded"
              >
                Clear
              </button>
            </div>
          ) : (
            <div className="flex flex-row my-2">
              <select
                className="border p-2 mr-2"
                value={selectedMonth}
                onChange={(e) => handleMonthSelect(e.target.value)}
              >
                <option value="" disabled>
                  Select Month
                </option>
                {months.map((month) => (
                  <option
                    key={month}
                    value={month}
                    onClick={() => filterByMonthAll(month)}
                  >
                    {month}
                  </option>
                ))}
              </select>
              <select
                name="filterDay"
                id="filterDay"
                className="border p-2 mr-2"
                value={filterDay}
                onChange={(e) => setFilterDay(e.target.value)}
                disabled={!filterMonth} // Disable the day filter if no month is selected
              >
                <option value="" disabled>
                  Filter by Day
                </option>
                {filterMonth &&
                  Array.from(
                    { length: getDaysInMonth(filterMonth) },
                    (_, i) => i + 1
                  ).map((day) => (
                    <option key={day} value={day}>
                      {day}
                    </option>
                  ))}
              </select>

              <select
                name="filterStatusP"
                id="filterStatusP"
                className="border p-2 mr-2"
                value={filterStatusP}
                onChange={(e) => setFilterStatusP(e.target.value)}
              >
                <option value="" disabled>
                  Filter by Status
                </option>
                <option value="Ordered">Ordered</option>
                <option value="Received">Received</option>
                <option value="Sent">Sent</option>
                <option value="Return Received">Return Received</option>
                <option value="Return Sent">Return Sent</option>
              </select>
              <label htmlFor="year-select" className="mx-3 font-bold">
                Year:
              </label>
              <input
                id="year-select"
                type="number"
                value={selectedYear}
                onChange={handleYearChange}
                min="2000"
                max={new Date().getFullYear()}
              />
              <button
                onClick={handleClear}
                className="bg-slate-600 hover:bg-slate-500 text-white font-bold px-4 rounded"
              >
                Clear
              </button>
            </div>
          )}
          {/* Conditionally render the select for mobile screens */}
          {isMobile ? (
            <select
              className="border p-2 mr-2"
              value={selectedMonth}
              onChange={(e) => handleMonthSelect(e.target.value)}
            >
              <option value="" disabled>
                Select Month
              </option>
              {months.map((month) => (
                <option
                  key={month}
                  value={month}
                  onClick={() => filterByMonthAll(month)}
                >
                  {month}
                </option>
              ))}
            </select>
          ) : (
            <div className="flex justify-center mb-4">
              {months.map((month) => (
                <button
                  key={month}
                  onClick={() => filterByMonthAll(month)}
                  className={`px-4 py-2 rounded-md mx-2 ${
                    selectedMonth === month
                      ? "bg-blue-600 text-white"
                      : "bg-gray-200"
                  }`}
                >
                  {month}
                </button>
              ))}
              <button
                onClick={() => filterByMonthAll("")}
                className={`px-4 py-2 rounded-md mx-2 ${
                  selectedMonth === ""
                    ? "bg-blue-600 text-white"
                    : "bg-gray-200"
                }`}
              >
                All
              </button>
            </div>
          )}
          <table className="border-collapse table-auto w-full mb-10 overflow-x-scroll">
            <thead>
              <tr>
                <th className="border-b-1 pb-3 border-slate-600 text-left bg-gray-100">
                  #
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left bg-gray-200">
                  Brand
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left bg-gray-100">
                  Model
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                  Storage
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                  Ram
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                  Sim
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                  Images
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                  Delivery Status
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                  Status
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                  Rate
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                  soldPrice
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                  buyer
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                  phone
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                  seller
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                  Date
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                  Receiver
                </th>
                <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => (
                <tr
                  key={item.ID}
                  className="border-b-1 border-slate-600 text-left m-0"
                >
                  {isEditing === item.ID ? (
                    <>
                      <td className="text-left font-bold text-slate-800 m-0 bg-gray-100">
                        {item.OrderNumber}
                      </td>
                      <td className="bg-gray-200">{item.Brand}</td>
                      <td className="bg-gray-100">{item.Model}</td>
                      <td className="bg-gray-200">{item.Storage}</td>
                      <td className="bg-gray-100">{item.Ram}</td>
                      <td className="bg-gray-200">{item.Sim}</td>

                      <td className="bg-gray-100">
                        {item.ImageFilename1 !== "" ||
                        item.ImageFilename2 !== "" ? (
                          <div className="m-0 flex flex-col ">
                            {/* Image upload inputs */}
                            <input
                              type="file"
                              onChange={(e) => setEditImage1(e.target.files[0])}
                              accept="image/*"
                              className="w-full"
                            />
                            <input
                              type="file"
                              onChange={(e) => setEditImage2(e.target.files[0])}
                              accept="image/*"
                              className="w-full"
                            />
                          </div>
                        ) : (
                          <div className="m-0 w-full bg-red-300">
                            {/* Image upload inputs */}
                            <input
                              type="file"
                              onChange={(e) => setEditImage1(e.target.files[0])}
                              accept="image/*"
                              className="w-full bg-red-500"
                            />
                            <input
                              type="file"
                              onChange={(e) => setEditImage2(e.target.files[0])}
                              accept="image/*"
                              className="w-full"
                            />
                          </div>
                        )}
                      </td>

                      <td className="bg-gray-200">
                        <div className="relative inline-block text-left">
                          <div className="flex flex-row justify-around">
                            <p className="inline-flex justify-around gap-x-1.5 rounded-md bg-gray-100  w-20 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-gray-50">
                              {item.DeliveryStat || "Options"}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="bg-gray-100">
                        <div className="relative inline-block text-left">
                          <div className="flex flex-row justify-around">
                            <button
                              type="button"
                              className="inline-flex justify-around gap-x-1.5 rounded-md bg-gray-100  w-20 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-gray-50"
                            >
                              {item.ourStat || "Options"}
                            </button>
                          </div>
                        </div>
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                        <input
                          className="border w-full"
                          type="text"
                          placeholder="rate"
                          value={
                            editData.rate !== undefined
                              ? editData.rate
                              : item.rate
                          }
                          onChange={(e) => handleChange("rate", e.target.value)}
                        />
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                        <input
                          className="border w-full"
                          type="text"
                          placeholder="Price"
                          value={
                            editData.soldPrice !== undefined
                              ? editData.soldPrice
                              : item.soldPrice
                          }
                          onChange={(e) =>
                            handleChange("soldPrice", e.target.value)
                          }
                        />
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                        <input
                          className="border w-full"
                          type="text"
                          placeholder="buyer"
                          value={
                            editData.buyer !== undefined
                              ? editData.buyer
                              : item.buyer
                          }
                          onChange={(e) =>
                            handleChange("buyer", e.target.value)
                          }
                        />
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                        <input
                          className="border w-full"
                          type="text"
                          placeholder="phone"
                          value={
                            editData.phone !== undefined
                              ? editData.phone
                              : item.phone
                          }
                          onChange={(e) =>
                            handleChange("phone", e.target.value)
                          }
                        />
                      </td>

                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                        <div className="relative inline-block text-left">
                          <div className="flex flex-row justify-around">
                            <button
                              type="button"
                              className="inline-flex justify-around gap-x-1.5 rounded-md bg-gray-100  w-20 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-gray-50"
                              id="menu-buttonn"
                              aria-expanded={dropdownOpenSeller === index}
                              aria-haspopup="true"
                              onClick={() => toggleDropdownSeller(index)}
                            >
                              {item.seller || "select"}
                              <svg
                                className="-mr-1 h-5 w-5 text-gray-400"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                          </div>
                          {dropdownOpenSeller === index && (
                            <div
                              className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                              aria-orientation="vertical"
                              aria-labelledby="menu-buttonn"
                              tabIndex="-1"
                            >
                              <div className="py-1" ref={dropdownRefSeller}>
                                <button
                                  className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                  role="menuitem"
                                  tabIndex="-1"
                                  onClick={() =>
                                    handleOptionSelectSeller(
                                      index,
                                      item.ID,
                                      "Yeabkal"
                                    )
                                  }
                                >
                                  Yeabkal
                                </button>
                                <button
                                  className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                  role="menuitem"
                                  tabIndex="-1"
                                  onClick={() =>
                                    handleOptionSelectSeller(
                                      index,
                                      item.ID,
                                      "Natnael"
                                    )
                                  }
                                >
                                  Natnael
                                </button>
                                <button
                                  className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                  role="menuitem"
                                  tabIndex="-1"
                                  onClick={() =>
                                    handleOptionSelectSeller(
                                      index,
                                      item.ID,
                                      "Yeabtsega"
                                    )
                                  }
                                >
                                  Yeabtsega
                                </button>
                                <button
                                  className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                  role="menuitem"
                                  tabIndex="-1"
                                  onClick={() =>
                                    handleOptionSelectSeller(
                                      index,
                                      item.ID,
                                      "Dibora"
                                    )
                                  }
                                >
                                  Dibora
                                </button>
                                <button
                                  className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                  role="menuitem"
                                  tabIndex="-1"
                                  onClick={() =>
                                    handleOptionSelectSeller(
                                      index,
                                      item.ID,
                                      "Yeabtsega"
                                    )
                                  }
                                >
                                  Kal & Nati
                                </button>
                                <button
                                  className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                  role="menuitem"
                                  tabIndex="-1"
                                  onClick={() =>
                                    handleOptionSelectSeller(
                                      index,
                                      item.ID,
                                      "Yeabtsega"
                                    )
                                  }
                                >
                                  Kal & Yeab
                                </button>
                                <button
                                  className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                  role="menuitem"
                                  tabIndex="-1"
                                  onClick={() =>
                                    handleOptionSelectSeller(
                                      index,
                                      item.ID,
                                      "Yeabtsega"
                                    )
                                  }
                                >
                                  Nati & Yeab
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>

                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                        <input
                          type="date"
                          placeholder={formatDate(item.salesDate)}
                          value={
                            editData.salesDate !== undefined
                              ? editData.salesDate
                              : item.salesDate
                          }
                          onChange={(e) =>
                            handleChange("salesDate", e.target.value)
                          }
                        />
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                        {item.receiver}
                      </td>
                      <td className="flex flex-row py-3 text-left m-0 bg-gray-100">
                        {loading ? (
                          <button className="inline-flex justify-around gap-x-1.5 rounded-md bg-green-500  w-12 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-gray-50">
                            <Oval
                              height={20}
                              width={20}
                              color="#fff"
                              wrapperStyle={{}}
                              wrapperClass=""
                              visible={true}
                              ariaLabel="oval-loading"
                              secondaryColor="#4fa94d"
                              strokeWidth={2}
                              strokeWidthSecondary={2}
                            />
                          </button>
                        ) : (
                          <button
                            className="inline-flex justify-around gap-x-1.5 rounded-md bg-green-500  w-12 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-gray-50"
                            onClick={() => handleSave(item.ID)}
                          >
                            Save
                          </button>
                        )}
                        <button
                          className="inline-flex justify-around gap-x-1.5 rounded-md bg-red-500  w-12 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-red-300"
                          onClick={() => setIsEditing(null)}
                        >
                          Cancel
                        </button>
                      </td>
                    </>
                  ) : (
                    <>
                      <td className="border-b-1 pb-3 border-slate-600 text-left font-bold text-slate-800 m-0 bg-gray-100">
                        {item.OrderNumber}
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                        {item.Brand}
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                        {item.Model}
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                        {item.Storage}
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                        {item.Ram}
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                        {item.Sim}
                      </td>
                      <td className="border-b-1 pb-3 border-slate-700 bg-gray-100">
                        {item.ImageFilename1 !== "" ||
                        item.ImageFilename2 !== "" ? (
                          <div className="flex flex-row gap-2">
                            <a href={item.imageUrl1}>
                              <img
                                src={item.imageUrl1}
                                alt=""
                                height="30px"
                                width="50px"
                              />
                            </a>
                            <a href={item.imageUrl2}>
                              <img
                                src={item.imageUrl2}
                                alt=""
                                height="30px"
                                width="50px"
                              />
                            </a>
                          </div>
                        ) : (
                          <a href="../images/no-image.png">
                            <img
                              src={noImage}
                              alt=""
                              height="30px"
                              width="50px"
                            />
                          </a>
                        )}
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                        <div className="relative inline-block text-left">
                          <div className="flex flex-row justify-around">
                            <p className="inline-flex justify-around gap-x-1.5 rounded-md bg-slate-50 w-20 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm">
                              {item.DeliveryStat || "Options"}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                        <div className="relative inline-block text-left">
                          <div className="flex flex-row justify-around">
                            <button
                              type="button"
                              className={`inline-flex justify-around gap-x-1.5 rounded-md w-20 mr-1 py-2 text-sm font-semibold shadow-sm hover:bg-gray-500
                                            ${
                                              item.ourStat === "Sold"
                                                ? "bg-green-500 text-white"
                                                : ""
                                            }
                                            ${
                                              item.ourStat === "Received"
                                                ? "bg-blue-500 text-white"
                                                : ""
                                            }
                                            ${
                                              item.ourStat === "Sent"
                                                ? "bg-yellow-500 text-white"
                                                : ""
                                            }
                                            ${
                                              item.ourStat === "Returned"
                                                ? "bg-purple-500 text-white"
                                                : ""
                                            }
                                            ${
                                              item.ourStat === "Unpaid"
                                                ? "bg-red-500 text-white"
                                                : ""
                                            }
                                            ${
                                              !item.ourStat
                                                ? "bg-gray-100 text-blue-900"
                                                : ""
                                            }`}
                              // className="inline-flex justify-around gap-x-1.5 rounded-md bg-gray-100  w-20 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-gray-50"
                              id="menu-button"
                              aria-expanded={dropdownOpenOurStat === index}
                              aria-haspopup="true"
                              onClick={() => toggleDropdownOurStat(index)}
                            >
                              {item.ourStat || "Options"}
                              <svg
                                className="-mr-1 h-5 w-5 text-gray-400"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                          </div>
                          {dropdownOpenOurStat === index && (
                            <div
                              className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                              aria-orientation="vertical"
                              aria-labelledby="menu-button"
                              tabIndex="-1"
                              ref={dropdownRefOurStat}
                            >
                              <div className="py-1">
                                <button
                                  className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                  role="menuitem"
                                  tabIndex="-1"
                                  onClick={() =>
                                    handleOptionSelect(
                                      index,
                                      item.ID,
                                      "Received"
                                    )
                                  }
                                >
                                  Received
                                </button>
                                <button
                                  className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                  role="menuitem"
                                  tabIndex="-1"
                                  onClick={() =>
                                    handleOptionSelect(index, item.ID, "Sold")
                                  }
                                >
                                  Sold
                                </button>
                                <button
                                  className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                  role="menuitem"
                                  tabIndex="-1"
                                  onClick={() =>
                                    handleOptionSelect(index, item.ID, "Unpaid")
                                  }
                                >
                                  Unpaid
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                        {item.rate}
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                        {item.soldPrice}
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                        {item.buyer}
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                        {item.phone}
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                        {item.seller}
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                        {formatDate(item.salesDate)}
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                        <div className="relative inline-block text-left">
                          <div className="flex flex-row justify-around">
                            <button
                              type="button"
                              className="inline-flex justify-around gap-x-1.5 rounded-md bg-gray-100  w-20 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-gray-50"
                              id="menu-button-receiverr"
                              aria-expanded={dropdownOpenReceiver === index}
                              aria-haspopup="true"
                              onClick={() => toggleDropdownReceiver(index)}
                            >
                              {item.receiver || "select"}
                              <svg
                                className="-mr-1 h-5 w-5 text-gray-400"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                          </div>
                          {dropdownOpenReceiver === index && (
                            <div
                              className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                              aria-orientation="vertical"
                              aria-labelledby="menu-button-receiverr"
                              tabIndex="-1"
                            >
                              <div className="py-1" ref={dropdownRefReceiver}>
                                <button
                                  className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                  role="menuitem"
                                  tabIndex="-1"
                                  onClick={() =>
                                    handleOptionSelectReceiver(
                                      index,
                                      item.ID,
                                      "Yeabkal"
                                    )
                                  }
                                >
                                  Yeabkal
                                </button>

                                <button
                                  className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                  role="menuitem"
                                  tabIndex="-1"
                                  onClick={() =>
                                    handleOptionSelectReceiver(
                                      index,
                                      item.ID,
                                      "Natnael"
                                    )
                                  }
                                >
                                  Natnael
                                </button>
                                <button
                                  className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                  role="menuitem"
                                  tabIndex="-1"
                                  onClick={() =>
                                    handleOptionSelectReceiver(
                                      index,
                                      item.ID,
                                      "Yeabtsega"
                                    )
                                  }
                                >
                                  Yeabtsega
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                        <button
                          className="inline-flex justify-around gap-x-1.5 rounded-md bg-gray-200 w-12 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-gray-50"
                          onClick={() => handleEditClick(item)}
                        >
                          Edit
                        </button>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default CustomerService;
