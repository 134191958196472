import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Header from "../component/Header";
import noImage from "../images/errors.png";
import OthersTable from "../component/OthersTable";
import { Oval } from "react-loader-spinner";
import DailyUpdate from "../component/DailyUpdate";

const apiUrl = process.env.REACT_APP_API_URL;

function China() {
  const [brand, setBrand] = useState("Samsung");
  const [model, setModel] = useState("");
  const [modelOther, setModelOther] = useState("");
  const [sim, setSim] = useState("");
  const [ram, setRam] = useState("");
  const [storage, setStorage] = useState("");
  const [delivery, setDelivery] = useState("");
  const [deliveryOther, setDeliveryOther] = useState("");
  const [status, setStatus] = useState("");
  const [price, setPrice] = useState("");
  const [priceOther, setPriceOther] = useState("");
  const [totalPrice, setTotalPrice] = useState("");
  const [filterMonth, setFilterMonth] = useState("");
  const [filterDay, setFilterDay] = useState("");
  const [filterStatusP, setFilterStatusP] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [imageFile1, setImageFile1] = useState(null); // For the first image
  const [imageFile2, setImageFile2] = useState(null); // For the second image
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [totalSent, setTotalSent] = useState("");
  const [totalReceivedCal, setTotalReceivedCal] = useState("");
  const [totalOrder, setTotalOrder] = useState("");
  const [quantityOther, setQuantityOther] = useState("");
  const [sortOption, setSortOption] = useState("month"); // Default to sorting by month

  const dropdownRef = useRef(null);

  const monthToNumber = (month) => {
    const months = {
      JAN: 1,
      FEB: 2,
      MAR: 3,
      APR: 4,
      MAY: 5,
      JUN: 6,
      JUL: 7,
      AUG: 8,
      SEP: 9,
      OCT: 10,
      NOV: 11,
      DEC: 12,
    };
    return months[month] || 0;
  };

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const sortData = (data, option) => {
    switch (option) {
      case "month":
        return data.sort((a, b) => {
          const [aMonth, aNumber] = [
            a.OrderNumber.slice(0, 3),
            parseInt(a.OrderNumber.slice(3), 10),
          ];
          const [bMonth, bNumber] = [
            b.OrderNumber.slice(0, 3),
            parseInt(b.OrderNumber.slice(3), 10),
          ];
          return aMonth !== bMonth
            ? bMonth.localeCompare(aMonth)
            : aNumber - bNumber;
        });
      case "orderAsc":
        return data.sort(
          (a, b) =>
            parseInt(a.OrderNumber.slice(3), 10) -
            parseInt(b.OrderNumber.slice(3), 10)
        );
      case "orderDesc":
        return data.sort(
          (a, b) =>
            parseInt(b.OrderNumber.slice(3), 10) -
            parseInt(a.OrderNumber.slice(3), 10)
        );
      default:
        return data;
    }
  };

  const handleSortChange = (option) => {
    setSortOption(option);
    const sortedData = sortData([...data], option); // Spread to avoid mutating original array
    setData(sortedData);
    setFilteredData(filterDataByMonth(sortedData, selectedMonth));
  };

  // Get current month in short form
  const getCurrentMonthShortForm = () => {
    const monthIndex = new Date().getMonth();
    return months[monthIndex];
  };

  const sortByOrderNumber = (data) => {
    return data.sort((a, b) => {
      const [aMonth, aNumber] = [
        a.OrderNumber.slice(0, 3),
        parseInt(a.OrderNumber.slice(3), 10),
      ];
      const [bMonth, bNumber] = [
        b.OrderNumber.slice(0, 3),
        parseInt(b.OrderNumber.slice(3), 10),
      ];
      return aMonth !== bMonth
        ? bMonth.localeCompare(aMonth)
        : aNumber - bNumber;
    });
  };

  const filterDataByMonth = (data, month) => {
    return data.filter((item) => item.OrderNumber.startsWith(month));
  };

  const currentMonthShortForm = () => {
    const monthIndex = new Date().getMonth(); // Get current month index (0-11)
    return months[monthIndex];
  };
  const [selectedMonth, setSelectedMonth] = useState(currentMonthShortForm());

  const filterByMonthAll = (month = getCurrentMonthShortForm()) => {
    setSelectedMonth(month);
    const filtered = data.filter((item) => item.OrderNumber.startsWith(month));
    setFilteredData(filtered);

    const sum = filtered.reduce(
      (acc, item) => acc + parseFloat(item.Price || 0),
      0
    );
    const receivedTotal = filtered.filter(
      (item) => item.Status === "Received"
    ).length;
    const sentTotal = filtered.filter((item) => item.Status === "Sent").length;
    const ordered = filtered.length;

    setTotalPrice(sum);
    setTotalReceivedCal(receivedTotal);
    setTotalSent(sentTotal);
    setTotalOrder(ordered);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/items`);
      const fetchedData = response.data.map((item) => ({
        ...item,
        imageUrl1: `${apiUrl}/uploads/${item.ImageFilename1}`, // Construct the first image URL
        imageUrl2: `${apiUrl}/uploads/${item.ImageFilename2}`, // Construct the image URL
      }));
      const sortedData = sortByOrderNumber(fetchedData);

      setData(sortedData);
      setFilteredData(filterDataByMonth(sortedData, selectedMonth)); // Filter data by current month
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  const updateStatus = async (itemId, status) => {
    try {
      setLoading(true);
      await axios.put(`${apiUrl}/updateStatus`, { id: itemId, status });
      setLoading(false);
      // Fetch updated data after successful status update
      fetchData();
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  const handleOptionSelect = (index, itemId, status) => {
    setDropdownOpen(null); // Close the dropdown

    // Update the status in the frontend optimistically
    setData(
      data.map((item) =>
        item.ID === itemId ? { ...item, Status: status } : item
      )
    );

    // Update the status in the backend
    updateStatus(itemId, status);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const toggleDropdown = (index) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Adjust this to your preference

  const [initialData, setInitialData] = useState({}); // Store the initial row data when editing starts

  const [isEditing, setIsEditing] = useState(null);
  const [editData, setEditData] = useState({
    brand: "",
    model: "",
    sim: "",
    ram: "",
    storage: "",
    status: "",
    delivery: "",
    price: "",
  });

  const handleEditClick = (item) => {
    setIsEditing(item.ID);
    setInitialData({
      brand: item.Brand,
      model: item.Model,
      sim: item.Sim,
      ram: item.Ram,
      storage: item.Storage,
      status: item.Status,
      delivery: item.DeliveryFee,
      price: item.Price,
    });
    setEditData({}); // Start with an empty object
  };

  const handleChange = (field, value) => {
    setEditData((prev) => ({
      ...prev,
      [field]: value !== initialData[field] ? value : undefined, // Set value if changed, otherwise undefined
    }));
  };

  // Function to handle saving the edited data
  const handleSave = async (id) => {
    console.log("ini", initialData);
    const completeEditData = {
      brand: editData.brand !== undefined ? editData.brand : initialData.brand,
      model: editData.model !== undefined ? editData.model : initialData.model,
      sim: editData.sim !== undefined ? editData.sim : initialData.sim,
      ram: editData.ram !== undefined ? editData.ram : initialData.ram,
      price: editData.price !== undefined ? editData.price : initialData.price,
      storage:
        editData.storage !== undefined ? editData.storage : initialData.storage,
      status:
        editData.status !== undefined ? editData.status : initialData.status,
      delivery:
        editData.delivery !== undefined
          ? editData.delivery
          : initialData.delivery,
    };
    console.log("completeEditData", completeEditData);
    try {
      setLoading(true);
      const response = await axios.put(
        `${apiUrl}/updateItem/${id}`,
        completeEditData
      );
      setData(data.map((item) => (item.ID === id ? response.data : item)));
      setIsEditing(null);
      setLoading(false);
      fetchData();
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  const filterByMonth = (data, month) => {
    if (!month) return data;
    return data.filter((item) => {
      const itemMonth = new Date(item.TimeStamp).getMonth() + 1; // getMonth is 0-indexed
      return itemMonth === parseInt(month);
    });
  };

  const filterByDay = (data, day, month) => {
    if (!day || !month) return data; // Ensure month is selected
    return data.filter((item) => {
      const itemDate = new Date(item.TimeStamp);
      const itemMonth = itemDate.getMonth() + 1;
      const itemDay = itemDate.getDate();
      return itemMonth === parseInt(month) && itemDay === parseInt(day); // Filter by day within the selected month
    });
  };

  const filterStatus = (data, status) => {
    if (!status) return data;
    return data.filter((item) => item.Status === status); // Filter by status
  };

  // Update useEffect to handle filtering
  // useEffect(() => {
  //   let filtered = data;
  //   if (filterMonth) {
  //     filtered = filterByMonth(filtered, filterMonth);
  //   }
  //   if (filterDay && filterMonth) {
  //     // Only filter by day if month is selected
  //     filtered = filterByDay(filtered, filterDay, filterMonth);
  //   }
  //   if (filterStatusP) {
  //     filtered = filterStatus(filtered, filterStatusP);
  //   }
  //   setFilteredData(filtered);
  // }, [data, filterMonth, filterDay, filterStatusP]);

  // Calculate the number of days for the selected month
  const getDaysInMonth = (month) => {
    const year = new Date().getFullYear(); // Use the current year
    return new Date(year, month, 0).getDate(); // Get the last day of the month
  };

  const handleClear = () => {
    setFilterMonth("");
    setFilterDay("");
    setFilterStatusP("");
  };

  const handleMonthSelect = (month) => {
    setSelectedMonth(month);
    filterByMonthAll(month); // Hide the dropdown after selection
  };
  const [isMobile, setIsMobile] = useState(false);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // Mobile if width <= 768px
  };

  useEffect(() => {
    handleResize(); // Check initially
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handlePost = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const userId = localStorage.getItem("token");
      const formData = new FormData();

      formData.append("brand", brand);
      formData.append("model", model);
      formData.append("sim", sim);
      formData.append("ram", ram);
      formData.append("storage", storage);
      formData.append("status", status);
      formData.append("delivery", delivery);
      formData.append("price", price);

      if (imageFile1) {
        formData.append("images", imageFile1);
      }
      if (imageFile2) {
        formData.append("images", imageFile2);
      }

      await axios.post(`${apiUrl}/post`, formData, {
        headers: {
          Authorization: `Bearer ${userId}`,
          "Content-Type": "multipart/form-data",
        },
      });

      setLoading(false);
      fetchData();
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  const handleOtherProductPost = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const userId = localStorage.getItem("token");
      const formData = new FormData();

      formData.append("model", modelOther);
      formData.append("delivery", deliveryOther);
      formData.append("price", priceOther);
      formData.append("quantity", quantityOther);

      if (imageFile1) {
        formData.append("images", imageFile1);
      }
      if (imageFile2) {
        formData.append("images", imageFile2);
      }

      await axios.post(`${apiUrl}/postOthers`, formData, {
        headers: {
          Authorization: `Bearer ${userId}`,
          "Content-Type": "multipart/form-data",
        },
      });

      setLoading(false);
      fetchData();
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  return (
    <>
      <Header />
      <div className="items-center flex flex-col justify-center mt-5">
        {loading ? <p>Loading</p> : null}
        {errors ? (
          <p className="bg-red-500 text-zinc-100 rounded px-5 py-2">{errors}</p>
        ) : null}
        <div className="flex flex-col mt-10 justify-center w-3/4">
          <form onSubmit={handlePost}>
            <h1 className="mb-3 font-bold text-lg">Phones</h1>
            <div className="grid grid-cols-4 gap-4">
              <div className="relative w-full">
                <label
                  htmlFor="model"
                  className="absolute left-3 bg-white px-2 font text-gray-400"
                  style={{ top: "-12px" }}
                >
                  Brand
                </label>
                <select
                  name="brand"
                  id="brand"
                  className="border p-2 w-full rounded"
                  value={brand}
                  onChange={(e) => setBrand(e.target.value)}
                >
                  <option value="" disabled>
                    Brand
                  </option>
                  <option value="Samsung" selected>
                    Samsung
                  </option>
                  <option value="iPhone">iPhone</option>
                </select>
              </div>

              <div className="relative w-full">
                <label
                  htmlFor="model"
                  className="absolute left-3 bg-white px-2 font text-gray-400"
                  style={{ top: "-12px" }}
                >
                  Model
                </label>
                <select
                  name="model"
                  id="model"
                  className="border p-2 w-full rounded"
                  value={model}
                  onChange={(e) => setModel(e.target.value)}
                >
                  <option value="" disabled>
                    Model
                  </option>
                  <option value=" S24 Ultra"> S24 Ultra</option>
                  <option value=" S24+"> S24+</option>
                  <option value=" S24"> S24</option>
                  <option value=" S23 FE"> S23 FE</option>
                  <option value=" S23 Ultra"> S23 Ultra</option>
                  <option value=" S23+"> S23+</option>
                  <option value=" S23"> S23</option>
                  <option value=" S22 Ultra 5G"> S22 Ultra 5G</option>
                  <option value=" S22+ 5G"> S22+ 5G</option>
                  <option value=" S22 5G"> S22 5G</option>
                  <option value=" S21 FE 5G"> S21 FE 5G</option>
                  <option value=" S20 FE 2022"> S20 FE 2022</option>
                  <option value=" S21 Ultra 5G"> S21 Ultra 5G</option>
                  <option value=" S21+ 5G"> S21+ 5G</option>
                  <option value=" S21 5G"> S21 5G</option>
                  <option value=" S20 FE 5G"> S20 FE 5G</option>
                  <option value=" S20 FE"> S20 FE</option>
                  <option value=" S20 Ultra 5G"> S20 Ultra 5G</option>
                  <option value=" S20 Ultra"> S20 Ultra</option>
                  <option value=" S20+ 5G"> S20+ 5G</option>
                  <option value=" S20+"> S20+</option>
                  <option value=" S20 5G UW"> S20 5G UW</option>
                  <option value=" S20 5G"> S20 5G</option>
                  <option value=" S20"> S20</option>
                  <option value=" S10 5G"> S10 5G</option>
                  <option value=" S10+"> S10+</option>
                  <option value=" S10"> S10</option>
                  <option value=" S10e"> S10e</option>
                  <option value=" S10 Lite"> S10 Lite</option>
                  <option value=" Note 20 Ultra 5G"> Note20 Ultra 5G</option>
                  <option value=" Note 20 Ultra"> Note20 Ultra</option>
                  <option value=" Note 20 5G"> Note20 5G</option>
                  <option value=" Note 20"> Note20</option>
                  <option value=" Note 10 Lite"> Note10 Lite</option>
                  <option value=" Note 10+ 5G"> Note10+ 5G</option>
                  <option value=" Note 10+"> Note10+</option>
                  <option value=" Note 10 5G"> Note10 5G</option>
                  <option value=" Note 10"> Note10</option>
                  <option value=" Note 9"> Note 9</option>
                  <option value=" Note 8"> Note8</option>
                </select>
              </div>
              <div className="relative w-full">
                <label
                  htmlFor="sim"
                  className="absolute left-3 bg-white px-2 font text-gray-400"
                  style={{ top: "-12px" }}
                >
                  Sim
                </label>
                <select
                  name="sim"
                  id="sim"
                  className="border p-2 w-full rounded"
                  value={sim}
                  onChange={(e) => setSim(e.target.value)}
                >
                  <option value="" disabled>
                    Sim
                  </option>
                  <option value="dual">Dual</option>
                  <option value="single">Single</option>
                  <option value="esim">Single E-sim</option>
                </select>
              </div>
              <div className="relative w-full">
                <label
                  htmlFor="ram"
                  className="absolute left-3 bg-white px-2 font text-gray-400"
                  style={{ top: "-12px" }}
                >
                  Ram
                </label>
                <select
                  name="ram"
                  id="ram"
                  className="border p-2 w-full rounded"
                  value={ram}
                  onChange={(e) => setRam(e.target.value)}
                >
                  <option value="" disabled>
                    Ram
                  </option>
                  <option value="6 GB">6 GB</option>
                  <option value="8 GB">8 GB</option>
                  <option value="12 GB">12 GB</option>
                  <option value="16 GB">16 GB</option>
                </select>
              </div>
              <div className="relative w-full">
                <label
                  htmlFor="storage"
                  className="absolute left-3 bg-white px-2 font text-gray-400"
                  style={{ top: "-12px" }}
                >
                  Storage
                </label>
                <select
                  name="storage"
                  id="storage"
                  className="border mb-4 p-2 w-full rounded"
                  value={storage}
                  onChange={(e) => setStorage(e.target.value)}
                >
                  <option value="" disabled>
                    Storage
                  </option>
                  <option value="128 GB">128 GB</option>
                  <option value="256 GB">256 GB</option>
                  <option value="512 GB">512 GB</option>
                </select>
              </div>
              <div className="relative w-full">
                <label
                  htmlFor="price"
                  className="absolute left-3 bg-white px-2 font text-gray-400"
                  style={{ top: "-12px" }}
                >
                  Price
                </label>
                <input
                  className="border p-2 w-full rounded"
                  id="price"
                  type="text"
                  placeholder="900"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>

              <div className="relative w-full">
                <label
                  htmlFor="delivery"
                  className="absolute left-3 bg-white px-2 font text-gray-400"
                  style={{ top: "-12px" }}
                >
                  Delivery fee
                </label>
                <input
                  className="border p-2 w-full rounded"
                  id="delivery"
                  type="text"
                  placeholder="Delivery fee"
                  value={delivery}
                  onChange={(e) => setDelivery(e.target.value)}
                />
              </div>
              <div className="relative w-full">
                <label
                  htmlFor="status"
                  className="absolute left-3 bg-white px-2 font text-gray-400"
                  style={{ top: "-12px" }}
                >
                  Status
                </label>
                <select
                  name="status"
                  id="status"
                  className="border mb-4 p-2 w-full rounded"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="" disabled>
                    Status
                  </option>
                  <option value="Ordered">Ordered</option>
                  <option value="Received">Received</option>
                  <option value="Sent">Sent</option>
                  <option value="Return Received">Return Received</option>
                  <option value="Return Sent">Return Sent</option>
                </select>
              </div>
            </div>
            <div className="flex flex-col w-full">
              <div className="relative w-full">
                <label
                  htmlFor="file1"
                  className="absolute left-3 bg-white px-2 font text-gray-400"
                  style={{ top: "-12px" }}
                >
                  Front
                </label>
                <input
                  type="file"
                  id="file1"
                  onChange={(e) => setImageFile1(e.target.files[0])} // Save the first file in state
                  className="text-sm w-full rounded text-stone-500 border-1 file:mr-5 file:p-3 file:px-12 file:border-0 file:text-xs file:font-medium file:bg-stone-50 file:text-stone-700 hover:file:cursor-pointer hover:file:bg-blue-50 hover:file:text-gray-900 mb-3"
                />
              </div>
              <div className="relative w-full">
                <label
                  htmlFor="file2"
                  className="absolute left-3 bg-white px-2 font text-gray-400"
                  style={{ top: "-12px" }}
                >
                  Back
                </label>
                <input
                  type="file"
                  id="file2"
                  onChange={(e) => setImageFile2(e.target.files[0])} // Save the second file in state
                  className="text-sm w-full rounded text-stone-500 border-1 file:mr-5 file:p-3 file:px-12 file:border-0 file:text-xs file:font-medium file:bg-stone-50 file:text-stone-700 hover:file:cursor-pointer hover:file:bg-blue-50 hover:file:text-gray-900 mb-3"
                />
              </div>
              {loading ? (
                <button className="bg-slate-600 hover:bg-slate-500 text-white font-bold py-2 px-4 mt-2 rounded">
                  <Oval
                    height={20}
                    width={20}
                    color="#fff"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#4fa94d"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                </button>
              ) : (
                <button
                  className="bg-slate-600 hover:bg-slate-500 text-white font-bold py-2 px-4 mt-2 rounded"
                  type="submit"
                >
                  Add
                </button>
              )}
            </div>
          </form>

          {/********* Other products ****************************************/}

          <form onSubmit={handleOtherProductPost}>
            <div className=" flex flex-col w-full pt-3">
              <h1 className="mb-3 font-bold text-lg">Other Products</h1>
              <div className="grid grid-cols-4 gap-4">
                <div className="relative w-full">
                  <label
                    htmlFor="delivery"
                    className="absolute left-3 bg-white px-2 font text-gray-400"
                    style={{ top: "-12px" }}
                  >
                    Name
                  </label>
                  <input
                    className="border p-2 w-full rounded"
                    id="delivery"
                    type="text"
                    placeholder="Product Name"
                    value={modelOther}
                    onChange={(e) => setModelOther(e.target.value)}
                  />
                </div>

                <div className="relative w-full">
                  <label
                    htmlFor="price"
                    className="absolute left-3 bg-white px-2 font text-gray-400"
                    style={{ top: "-12px" }}
                  >
                    Price
                  </label>
                  <input
                    className="border p-2 w-full rounded"
                    id="price"
                    type="text"
                    placeholder="900"
                    value={priceOther}
                    onChange={(e) => setPriceOther(e.target.value)}
                  />
                </div>

                <div className="relative w-full">
                  <label
                    htmlFor="price"
                    className="absolute left-3 bg-white px-2 font text-gray-400"
                    style={{ top: "-12px" }}
                  >
                    Quantity
                  </label>
                  <input
                    className="border p-2 w-full rounded"
                    id="quantity"
                    type="text"
                    placeholder="3"
                    value={quantityOther}
                    onChange={(e) => setQuantityOther(e.target.value)}
                  />
                </div>

                <div className="relative w-full">
                  <label
                    htmlFor="delivery"
                    className="absolute left-3 bg-white px-2 font text-gray-400"
                    style={{ top: "-12px" }}
                  >
                    Delivery fee
                  </label>
                  <input
                    className="border p-2 w-full rounded"
                    id="delivery"
                    type="text"
                    placeholder="Delivery fee"
                    value={deliveryOther}
                    onChange={(e) => setDeliveryOther(e.target.value)}
                  />
                </div>
              </div>
              {loading ? (
                <button className="bg-slate-600 hover:bg-slate-500 text-white font-bold py-2 px-4 mt-3 rounded">
                  <Oval
                    height={20}
                    width={20}
                    color="#fff"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#4fa94d"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                </button>
              ) : (
                <button
                  className="bg-slate-600 hover:bg-slate-500 text-white font-bold py-2 px-4 mt-3 rounded"
                  type="submit"
                >
                  Add
                </button>
              )}
            </div>
          </form>
        </div>
        <div className=" border-1 border-slate-200 mt-20 w-3/4 mb-20 h-screen px-5 rounded-md">
          <div className="flex flex-col my-2">
            <h1 className="text-3xl font-bold text-gray-800">Products</h1>
          </div>
          {isMobile ? (
            <div className="flex flex-col justify-between my-2">
              <div className="flex flex-col my-2">
                <select
                  className="border p-2 mr-2"
                  value={selectedMonth}
                  onChange={(e) => handleMonthSelect(e.target.value)}
                >
                  <option value="" disabled>
                    Select Month
                  </option>
                  {months.map((month) => (
                    <option
                      key={month}
                      value={month}
                      onClick={() => filterByMonthAll(month)}
                    >
                      {month}
                    </option>
                  ))}
                </select>
                <select
                  name="filterDay"
                  id="filterDay"
                  className="border p-2 mr-2 my-2"
                  value={filterDay}
                  onChange={(e) => setFilterDay(e.target.value)}
                  disabled={!filterMonth} // Disable the day filter if no month is selected
                >
                  <option value="" disabled>
                    Filter by Day
                  </option>
                  {filterMonth &&
                    Array.from(
                      { length: getDaysInMonth(filterMonth) },
                      (_, i) => i + 1
                    ).map((day) => (
                      <option key={day} value={day}>
                        {day}
                      </option>
                    ))}
                </select>

                <select
                  name="filterStatusP"
                  id="filterStatusP"
                  className="border p-2 mr-2 mb-2"
                  value={filterStatusP}
                  onChange={(e) => setFilterStatusP(e.target.value)}
                >
                  <option value="" disabled>
                    Filter by Status
                  </option>
                  <option value="Ordered">Ordered</option>
                  <option value="Received">Received</option>
                  <option value="Sent">Sent</option>
                  <option value="Return Received">Return Received</option>
                  <option value="Return Sent">Return Sent</option>
                </select>

                <button
                  onClick={handleClear}
                  className="bg-slate-600 hover:bg-slate-500 text-white font-bold px-4 mb-2 rounded"
                >
                  Clear
                </button>
              </div>
              <div>
                <select
                  id="sort-select"
                  className="border p-2 mr-2 bg-slate-700 text-white"
                  value={sortOption}
                  onChange={(e) => handleSortChange(e.target.value)}
                >
                  <option value="">Sort by</option>
                  <option value="orderAsc">Order Number (Ascending)</option>
                  <option value="orderDesc">Order Number (Descending)</option>
                </select>
                {/* Rest of the component */}
              </div>
            </div>
          ) : (
            ""
          )}
          {/* Conditionally render the select for mobile screens */}
          {isMobile ? (
            <select
              className="border p-2 mr-2"
              value={selectedMonth}
              onChange={(e) => handleMonthSelect(e.target.value)}
            >
              <option value="" disabled>
                Select Month
              </option>
              {months.map((month) => (
                <option
                  key={month}
                  value={month}
                  onClick={() => filterByMonthAll(month)}
                >
                  {month}
                </option>
              ))}
            </select>
          ) : (
            <div className="flex justify-center mb-4">
              {months.map((month) => (
                <button
                  key={month}
                  onClick={() => {
                    filterByMonthAll(month);
                  }}
                  className={`px-4 py-2 rounded-md mx-2 ${
                    selectedMonth === month && selectedMonth !== ""
                      ? "bg-blue-600 text-white"
                      : "bg-gray-200"
                  }`}
                >
                  {month}
                </button>
              ))}
              <button
                onClick={() => {
                  setSelectedMonth("");
                  filterByMonthAll("");
                }}
                className={`px-4 py-2 rounded-md mx-2 ${
                  selectedMonth === "" && selectedMonth !== months
                    ? "bg-blue-600 text-white"
                    : "bg-gray-200"
                }`}
              >
                All
              </button>
            </div>
          )}
          <div className="relative max-h-[80vh] overflow-y-auto custom-scrollbar">
            <table className="w-full border-collapse">
              <thead className="sticky top-0 z-10 border-b border-slate-600 text-left bg-white">
                <tr>
                  <th className="sticky top-0 z-10 border-b border-slate-600 text-left  bg-gray-100">
                    #
                  </th>
                  <th className="sticky top-0 z-10 border-b border-slate-600 text-left bg-gray-200">
                    Brand
                  </th>
                  <th className="sticky top-0 z-10 border-b border-slate-600 text-left bg-gray-100">
                    Model
                  </th>
                  <th className="sticky top-0 z-10 border-b border-slate-600 text-left bg-gray-200">
                    Storage
                  </th>
                  <th className="sticky top-0 z-10 border-b border-slate-600 text-left bg-gray-100">
                    Ram
                  </th>
                  <th className="sticky top-0 z-10 border-b border-slate-600 text-left bg-gray-200">
                    Sim
                  </th>
                  <th className="sticky top-0 z-10 border-b border-slate-600 text-left bg-gray-100">
                    Price
                  </th>{" "}
                  {/* Custom color */}
                  <th className="sticky top-0 z-10 border-b border-slate-600 text-left bg-gray-200">
                    Delivery Fee
                  </th>
                  <th className="sticky top-0 z-10 border-b border-slate-600 text-left bg-gray-100">
                    Images
                  </th>
                  <th className="sticky top-0 z-10 border-b border-slate-600 text-left bg-gray-200">
                    Status
                  </th>
                  <th className="sticky top-0 z-10 border-b border-slate-600 text-left bg-gray-100">
                    Delivery Status
                  </th>
                  <th className="sticky top-0 z-10 border-b border-slate-600 text-left bg-gray-200">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item, index) => (
                  <tr key={item.ID} className=" border-b-1 border-slate-600">
                    {isEditing === item.ID ? (
                      <>
                        <td className="text-left m-0">{item.OrderNumber}</td>

                        <td className="text-left m-0">
                          <select
                            name="brand"
                            id="brand"
                            className="border w-full rounded"
                            value={
                              editData.brand !== undefined
                                ? editData.brand
                                : item.Brand
                            }
                            onChange={(e) =>
                              handleChange("brand", e.target.value)
                            }
                          >
                            <option value="" disabled>
                              Brand
                            </option>
                            <option value="Samsung" selected>
                              Samsung
                            </option>
                            <option value="iPhone">iPhone</option>
                          </select>
                        </td>
                        <td className="text-left m-0">
                          <select
                            name="model"
                            id="model"
                            className="border w-full rounded"
                            value={editData.model || item.Model} // Display editData.model or fall back to item.Model
                            onChange={(e) =>
                              handleChange("model", e.target.value)
                            }
                          >
                            <option value="" disabled>
                              Model
                            </option>
                            <option value="S24 Ultra">S24 Ultra</option>
                            <option value="S24+">S24+</option>
                            <option value="S24">S24</option>
                            <option value="S23 FE">S23 FE</option>
                            <option value="S23 Ultra">S23 Ultra</option>
                            <option value="S23+">S23+</option>
                            <option value="S23">S23</option>
                            <option value="S22 Ultra 5G">S22 Ultra 5G</option>
                            <option value="S22+ 5G">S22+ 5G</option>
                            <option value="S22 5G">S22 5G</option>
                            <option value="S21 FE 5G">S21 FE 5G</option>
                            <option value="S20 FE 2022">S20 FE 2022</option>
                            <option value="S21 Ultra 5G">S21 Ultra 5G</option>
                            <option value="S21+ 5G">S21+ 5G</option>
                            <option value="S21 5G">S21 5G</option>
                            <option value="S20 FE 5G">S20 FE 5G</option>
                            <option value="S20 FE">S20 FE</option>
                            <option value="S20 Ultra 5G">S20 Ultra 5G</option>
                            <option value="S20 Ultra">S20 Ultra</option>
                            <option value="S20+ 5G">S20+ 5G</option>
                            <option value="S20+">S20+</option>
                            <option value="S20 5G UW">S20 5G UW</option>
                            <option value="S20 5G">S20 5G</option>
                            <option value="S20">S20</option>
                            <option value="S10 5G">S10 5G</option>
                            <option value="S10+">S10+</option>
                            <option value="S10">S10</option>
                            <option value="S10e">S10e</option>
                            <option value="S10 Lite">S10 Lite</option>
                            <option value="Note 20 Ultra 5G">
                              Note 20 Ultra 5G
                            </option>
                            <option value="Note 20 Ultra">Note 20 Ultra</option>
                            <option value="Note 20 5G">Note 20 5G</option>
                            <option value="Note 20">Note 20</option>
                            <option value="Note 10 Lite">Note 10 Lite</option>
                            <option value="Note 10+ 5G">Note 10+ 5G</option>
                            <option value="Note 10+">Note 10+</option>
                            <option value="Note 10 5G">Note 10 5G</option>
                            <option value="Note 10">Note 10</option>
                            <option value="Note 9">Note 9</option>
                            <option value="Note 8">Note 8</option>
                          </select>
                        </td>
                        <td className="text-left m-0">
                          <select
                            name="storage"
                            id="storage"
                            className="border w-full rounded"
                            value={
                              editData.storage !== undefined
                                ? editData.storage
                                : item.Storage
                            }
                            onChange={(e) =>
                              handleChange("storage", e.target.value)
                            }
                          >
                            <option value="" disabled>
                              Storage
                            </option>
                            <option value="128 GB">128 GB</option>
                            <option value="256 GB">256 GB</option>
                            <option value="512 GB">512 GB</option>
                          </select>
                        </td>
                        <td className="text-left m-0">
                          <select
                            name="ram"
                            id="ram"
                            className="border w-full rounded"
                            value={
                              editData.ram !== undefined
                                ? editData.ram
                                : item.Ram
                            }
                            onChange={(e) =>
                              handleChange("ram", e.target.value)
                            }
                          >
                            <option value="" disabled>
                              Ram
                            </option>
                            <option value="6 GB">6 GB</option>
                            <option value="8 GB">8 GB</option>
                            <option value="12 GB">12 GB</option>
                            <option value="16 GB">16 GB</option>
                          </select>
                        </td>
                        <td className="text-left m-0">
                          <select
                            name="sim"
                            id="sim"
                            className="border w-full rounded"
                            value={
                              editData.sim !== undefined
                                ? editData.sim
                                : item.Sim
                            }
                            onChange={(e) =>
                              handleChange("sim", e.target.value)
                            }
                          >
                            <option value="" disabled>
                              Sim
                            </option>
                            <option value="dual">Dual</option>
                            <option value="single">Single</option>
                            <option value="esim">Single E-sim</option>
                          </select>
                        </td>
                        <td className="text-left m-0">
                          <input
                            className="border w-full"
                            type="text"
                            value={
                              editData.price !== undefined
                                ? editData.price
                                : item.Price
                            }
                            onChange={(e) =>
                              handleChange("price", e.target.value)
                            }
                          />
                        </td>
                        <td className="text-left m-0">
                          <input
                            className="border w-full"
                            type="text"
                            value={
                              editData.delivery !== undefined
                                ? editData.delivery
                                : item.DeliveryFee
                            }
                            onChange={(e) =>
                              handleChange("delivery", e.target.value)
                            }
                          />
                        </td>
                        <td className="">
                          {item.ImageFilename1 !== "" ||
                          item.ImageFilename2 !== "" ? (
                            <div className="flex flex-row gap-2">
                              <a href={item.imageUrl1}>
                                <img
                                  src={item.imageUrl1}
                                  alt=""
                                  height="30px"
                                  width="50px"
                                />
                              </a>
                              <a href={item.imageUrl2}>
                                <img
                                  src={item.imageUrl2}
                                  alt=""
                                  height="30px"
                                  width="50px"
                                />
                              </a>
                            </div>
                          ) : (
                            <a href="../images/no-image.png">
                              <img
                                src={noImage}
                                alt=""
                                height="30px"
                                width="50px"
                              />
                            </a>
                          )}
                        </td>
                        <td className="text-left m-0">
                          <div className="relative inline-block text-left">
                            <div className="flex flex-row justify-around">
                              <button
                                type="button"
                                className="inline-flex justify-around gap-x-1.5 rounded-md bg-gray-100  w-20 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-gray-50"
                                id="menu-button"
                                aria-expanded={dropdownOpen === index}
                                aria-haspopup="true"
                              >
                                {item.Status || "Options"}
                              </button>
                            </div>
                            {dropdownOpen === index && (
                              <div
                                className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                aria-orientation="vertical"
                                aria-labelledby="menu-button"
                                tabIndex="-1"
                              >
                                <div className="py-1">
                                  <button
                                    className="block w-full px-4 py-2 text-left text-sm text-gray-700 "
                                    role="menuitem"
                                    tabIndex="-1"
                                    onClick={() =>
                                      handleOptionSelect(
                                        index,
                                        item.ID,
                                        "Ordered"
                                      )
                                    }
                                  >
                                    Ordered
                                  </button>
                                  <button
                                    className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                    role="menuitem"
                                    tabIndex="-1"
                                    onClick={() =>
                                      handleOptionSelect(
                                        index,
                                        item.ID,
                                        "Received"
                                      )
                                    }
                                  >
                                    Received
                                  </button>
                                  <button
                                    className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                    role="menuitem"
                                    tabIndex="-1"
                                    onClick={() =>
                                      handleOptionSelect(index, item.ID, "Sent")
                                    }
                                  >
                                    Sent
                                  </button>
                                  <button
                                    className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                    role="menuitem"
                                    tabIndex="-1"
                                    onClick={() =>
                                      handleOptionSelect(index, item.ID, "Ren")
                                    }
                                  >
                                    Returned
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        </td>
                        <td className="text-left m-0">
                          <div className="relative inline-block text-left">
                            <div className="flex flex-row justify-around">
                              <p className="inline-flex justify-around gap-x-1.5 rounded-md bg-gray-100  w-20 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-gray-50">
                                {item.Status || "Options"}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="flex flex-row text-left m-0">
                          <button
                            className="inline-flex justify-around gap-x-1.5 rounded-md bg-gray-100  w-12 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-gray-50"
                            onClick={() => handleSave(item.ID)}
                          >
                            Save
                          </button>
                          <button
                            className="inline-flex justify-around gap-x-1.5 rounded-md bg-red-500  w-12 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-red-300"
                            onClick={() => setIsEditing(null)}
                          >
                            Cancel
                          </button>
                        </td>
                      </>
                    ) : (
                      <>
                        <td className="  text-left font-bold text-slate-800 m-0 bg-gray-100">
                          {item.OrderNumber}
                        </td>
                        <td className="text-left m-0 bg-gray-200">
                          {item.Brand}
                        </td>
                        <td className="text-left m-0 bg-gray-100">
                          {item.Model}
                        </td>
                        <td className="text-left m-0 bg-gray-200">
                          {item.Storage}
                        </td>
                        <td className="text-left m-0 bg-gray-100">
                          {item.Ram}
                        </td>
                        <td className="text-left m-0 bg-gray-200">
                          {item.Sim}
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                          {item.Price}
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                          {item.DeliveryFee}
                        </td>
                        <td className="border-b-1 pb-3 border-slate-700 bg-gray-100">
                          {item.ImageFilename1 !== "" ||
                          item.ImageFilename2 !== "" ? (
                            <div className="flex flex-row gap-2">
                              <a href={item.imageUrl1}>
                                <img
                                  src={item.imageUrl1}
                                  alt=""
                                  height="30px"
                                  width="50px"
                                />
                              </a>
                              <a href={item.imageUrl2}>
                                <img
                                  src={item.imageUrl2}
                                  alt=""
                                  height="30px"
                                  width="50px"
                                />
                              </a>
                            </div>
                          ) : (
                            <a href="../images/no-image.png">
                              <img
                                src={noImage}
                                alt=""
                                height="30px"
                                width="50px"
                              />
                            </a>
                          )}
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                          <div className="relative inline-block text-left">
                            <div className="flex flex-row justify-around">
                              <button
                                type="button"
                                className="inline-flex justify-around gap-x-1.5 rounded-md bg-gray-300  w-20 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-gray-50"
                                id="menu-button"
                                aria-expanded={dropdownOpen === index}
                                aria-haspopup="true"
                                onClick={() => toggleDropdown(index)}
                              >
                                {item.Status || "Options"}
                                <svg
                                  className="-mr-1 h-5 w-5 text-gray-400"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </button>
                            </div>
                            {dropdownOpen === index && (
                              <div
                                className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                aria-orientation="vertical"
                                aria-labelledby="menu-button"
                                tabIndex="-1"
                              >
                                <div className="py-1">
                                  <button
                                    className="block w-full px-4 py-2 text-left text-sm text-gray-700 "
                                    role="menuitem"
                                    tabIndex="-1"
                                    onClick={() =>
                                      handleOptionSelect(
                                        index,
                                        item.ID,
                                        "Ordered"
                                      )
                                    }
                                  >
                                    Ordered
                                  </button>
                                  <button
                                    className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                    role="menuitem"
                                    tabIndex="-1"
                                    onClick={() =>
                                      handleOptionSelect(
                                        index,
                                        item.ID,
                                        "Received"
                                      )
                                    }
                                  >
                                    Received
                                  </button>
                                  <button
                                    className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                    role="menuitem"
                                    tabIndex="-1"
                                    onClick={() =>
                                      handleOptionSelect(index, item.ID, "Sent")
                                    }
                                  >
                                    Sent
                                  </button>
                                  <button
                                    className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                    role="menuitem"
                                    tabIndex="-1"
                                    onClick={() =>
                                      handleOptionSelect(index, item.ID, "Ren")
                                    }
                                  >
                                    Returned
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                          <div className="relative inline-block text-left">
                            <div className="flex flex-row justify-around">
                              <p className="inline-flex justify-around gap-x-1.5 rounded-md bg-slate-500 text-white w-20 mr-1 py-2 text-sm font-semibold  shadow-sm">
                                {item.Status || "Options"}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                          <button
                            className="inline-flex justify-around gap-x-1.5 rounded-md bg-gray-700 w-12 mr-1 py-2 text-sm font-semibold  shadow-sm  hover:bg-gray-500 text-white"
                            onClick={() => handleEditClick(item)}
                          >
                            Edit
                          </button>
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <OthersTable />
      </div>
      <DailyUpdate />
    </>
  );
}

export default China;
