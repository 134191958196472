import React, { useState, useEffect } from "react";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

function FinanceTable() {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [data, setData] = useState([]);
  const [dataOther, setDataOther] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredDataOther, setFilteredDataOther] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Added for year filter

  // Get the current month in short form
  const currentMonthShortForm = () => {
    const monthIndex = new Date().getMonth(); // Get current month index (0-11)
    const monthNames = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    return monthNames[monthIndex];
  };

  const [selectedMonth, setSelectedMonth] = useState(currentMonthShortForm()); // Initialize to current month

  const monthToNumber = (month) => {
    const months = {
      JAN: 1,
      FEB: 2,
      MAR: 3,
      APR: 4,
      MAY: 5,
      JUN: 6,
      JUL: 7,
      AUG: 8,
      SEP: 9,
      OCT: 10,
      NOV: 11,
      DEC: 12,
    };
    return months[month] || 0;
  };

  const parseMonthFromOrderNumber = (orderNumber) => {
    const monthPart = orderNumber.startsWith("OP")
      ? orderNumber.slice(2, 5)
      : orderNumber.slice(0, 3);
    return monthPart.toUpperCase();
  };

  const sortByOrderNumber = (data) => {
    return data.sort((a, b) => {
      const [aMonth, aNumber] = [
        parseMonthFromOrderNumber(a.OrderNumber),
        parseInt(a.OrderNumber.slice(-2)),
      ];
      const [bMonth, bNumber] = [
        parseMonthFromOrderNumber(b.OrderNumber),
        parseInt(b.OrderNumber.slice(-2)),
      ];
      const aMonthNum = monthToNumber(aMonth);
      const bMonthNum = monthToNumber(bMonth);

      if (aMonthNum !== bMonthNum) {
        return bMonthNum - aMonthNum; // Sort by month first (descending)
      }
      return bNumber - aNumber; // Then by number (descending)
    });
  };

  const filterDataByMonth = (data, month) => {
    return data.filter(
      (item) => parseMonthFromOrderNumber(item.OrderNumber) === month
    );
  };

  const filterDataByYear = (data, year) => {
    return data.filter((item) => {
      const itemYear = new Date(item.TimeStamp).getFullYear();
      return itemYear === parseInt(year, 10);
    });
  };

  const applyFilters = (data, month, year) => {
    let filtered = filterDataByYear(data, year);
    filtered = filterDataByMonth(filtered, month);
    return filtered;
  };

  const filterByMonthAndYear = (
    month = currentMonthShortForm(),
    year = new Date().getFullYear()
  ) => {
    setSelectedMonth(month);
    setSelectedYear(year);
    const filtered = applyFilters(data, month, year);
    setFilteredData(filtered);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/items`);
      const fetchedData = response.data.map((item) => ({
        ...item,
        imageUrl1: `${apiUrl}/uploads/${item.ImageFilename1}`,
        imageUrl2: `${apiUrl}/uploads/${item.ImageFilename2}`,
      }));
      const sortedData = sortByOrderNumber(fetchedData);
      setData(sortedData);
      setFilteredData(filterDataByMonth(sortedData, selectedMonth)); // Initialize with current month data
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  const fetchDataOther = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/otherItems`);
      const fetchedData = response.data.map((item) => ({
        ...item,
        imageUrl1: `${apiUrl}/uploads/${item.ImageFilename1}`,
        imageUrl2: `${apiUrl}/uploads/${item.ImageFilename2}`,
      }));
      const sortedData = sortByOrderNumber(fetchedData);
      setDataOther(sortedData);
      setFilteredDataOther(filterDataByMonth(sortedData, selectedMonth)); // Initialize with current month data
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  const handleYearChange = (event) => {
    const year = event.target.value;
    filterByMonthAndYear(selectedMonth, year);
  };

  const handleMonthChange = (event) => {
    const month = event.target.value;
    setSelectedMonth(month);
    setFilteredData(filterDataByMonth(data, month));
    setFilteredDataOther(filterDataByMonth(dataOther, month));
  };

  useEffect(() => {
    fetchData();
    fetchDataOther();
  }, []);

  const totalExpense = filteredData.reduce(
    (acc, item) => acc + Number(item.Price || 0),
    0
  );
  const totalDelivery = filteredData.reduce(
    (acc, item) => acc + Number(item.DeliveryFee || 0),
    0
  );
  const orderCommission = filteredData
    .filter((item) => Number(item.Price) > 0) // Exclude items with price 0
    .reduce((acc, item) => acc + Number(item.com1), 0);

  const shipCommission = filteredData.reduce(
    (acc, item) => acc + Number(item.com2 || 0),
    0
  );
  // Calculate individual profit and total profit

  const totalExpenseOther = filteredDataOther.reduce(
    (acc, item) => acc + Number(item.price || 0),
    0
  );
  const totalDeliveryOther = filteredDataOther.reduce(
    (acc, item) => acc + Number(item.Delivery || 0),
    0
  );
  const orderCommissionOther = filteredDataOther.reduce(
    (acc, item) => acc + Number(item.com1),
    0
  );
  const shipCommissionOther = filteredDataOther.reduce(
    (acc, item) => acc + Number(item.com2),
    0
  );

  return (
    <div className="items-center flex flex-col justify-center mt-5 overflow-auto w-full">
      <div className="border-1 border-slate-500 mb-20 px-10 rounded-md overflow-auto">
        <div className="my-2">
          <label htmlFor="month-select" className="font-bold mr-2">
            Select Month:
          </label>
          <select
            id="month-select"
            onChange={handleMonthChange}
            value={selectedMonth}
          >
            <option value="">All</option>
            <option value="JAN">January</option>
            <option value="FEB">February</option>
            <option value="MAR">March</option>
            <option value="APR">April</option>
            <option value="MAY">May</option>
            <option value="JUN">June</option>
            <option value="JUL">July</option>
            <option value="AUG">August</option>
            <option value="SEP">September</option>
            <option value="OCT">October</option>
            <option value="NOV">November</option>
            <option value="DEC">December</option>
          </select>
          <label htmlFor="year-select">Year:</label>
              <input
                id="year-select"
                type="number"
                value={selectedYear}
                onChange={handleYearChange}
                min="2000"
                max={new Date().getFullYear()}
              />
        </div>

        <h1 className="font-bold text-2xl">
          Finance - {selectedMonth || "All Months"}
        </h1>
        <p className="font-bold text-xl">Phone</p>
        <table className="border-collapse table-auto w-full mb-10 overflow-x-scroll">
          <thead>
            <tr>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                Total Orders
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                Total Expense
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                Total Delivery
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                Order Commission
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                Ship Commission
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                {filteredData.length}
              </td>
              <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                {totalExpense}
              </td>
              <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                {totalDelivery}
              </td>
              <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                {orderCommission}
              </td>
              <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                {shipCommission}
              </td>
            </tr>
          </tbody>
        </table>

        <p className="font-bold text-xl">
          Other Products - {selectedMonth || "All Months"}
        </p>
        <table className="border-collapse table-auto w-full mb-10 overflow-x-scroll">
          <thead>
            <tr>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                Total Orders
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                Total Expense
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                Total Delivery
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                Order Commission
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                Ship Commission
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                {filteredDataOther.length}
              </td>
              <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                {totalExpenseOther}
              </td>
              <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                {totalDeliveryOther}
              </td>
              <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                {orderCommissionOther}
              </td>
              <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                {shipCommissionOther}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default FinanceTable;
