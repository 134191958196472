import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

function OtherProducts() {
  const [totalPrice, setTotalPrice] = useState("");
  const [filterModel, setFilterModel] = useState("");
  const [filterMonth, setFilterMonth] = useState("");
  const [filterDay, setFilterDay] = useState("");
  const [filterStatusP, setFilterStatusP] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);
  const [otherData, setOtherData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [totalSent, setTotalSent] = useState("");
  const [totalReceivedCal, setTotalReceivedCal] = useState("");
  const [totalOrder, setTotalOrder] = useState("");
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Added for year filter

  let totalYuanSum = 0;
  let totalProfit = 0;

  const [dropdownOpenReceiver, setDropdownOpenReceiver] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(null);

  // Separate refs for each dropdown
  const dropdownRefReceiver = useRef(null);
  const dropdownRef = useRef(null);

  const toggleDropdownReceiver = (index) => {
    setDropdownOpenReceiver(dropdownOpenReceiver === index ? null : index);
  };
  const toggleDropdown = (index) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  };

  // Handle clicking outside for all dropdowns
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(null);
    }
    if (
      dropdownRefReceiver.current &&
      !dropdownRefReceiver.current.contains(event.target)
    ) {
      setDropdownOpenReceiver(null);
    }
  };
  const monthToNumber = (month) => {
    const months = {
      JAN: 1,
      FEB: 2,
      MAR: 3,
      APR: 4,
      MAY: 5,
      JUN: 6,
      JUL: 7,
      AUG: 8,
      SEP: 9,
      OCT: 10,
      NOV: 11,
      DEC: 12,
    };
    return months[month] || 0;
  };

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const currentMonthShortForm = () => {
    const monthIndex = new Date().getMonth(); // Get current month index (0-11)
    const monthNames = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    return monthNames[monthIndex];
  };

  const [selectedMonth, setSelectedMonth] = useState(currentMonthShortForm()); // Initialize to current month

  // Get current month in short form
  const getCurrentMonthShortForm = () => {
    const monthIndex = new Date().getMonth();
    return months[monthIndex];
  };

  const parseMonthFromOrderNumber = (orderNumber) => {
    const monthPart = orderNumber.startsWith("OP")
      ? orderNumber.slice(2, 5)
      : orderNumber.slice(0, 3);
    return monthPart.toUpperCase();
  };

  const sortByOrderNumber = (data) => {
    return data.sort((a, b) => {
      const [aMonth, aNumber] = [
        parseMonthFromOrderNumber(a.OrderNumber),
        parseInt(a.OrderNumber.slice(-2)),
      ];
      const [bMonth, bNumber] = [
        parseMonthFromOrderNumber(b.OrderNumber),
        parseInt(b.OrderNumber.slice(-2)),
      ];
      const aMonthNum = monthToNumber(aMonth);
      const bMonthNum = monthToNumber(bMonth);

      if (aMonthNum !== bMonthNum) {
        return bMonthNum - aMonthNum; // Sort by month first (descending)
      }
      return bNumber - aNumber; // Then by number (descending)
    });
  };

  const filterByMonthAndYear = (
    month = currentMonthShortForm(),
    year = new Date().getFullYear()
  ) => {
    setSelectedMonth(month);
    setSelectedYear(year);
    const filtered = applyFilters(data, month, year);
    setFilteredData(filtered);

    const sum = filtered.reduce(
      (acc, item) => acc + parseFloat(item.Price || 0),
      0
    );
    const receivedTotal = filtered.filter(
      (item) => item.Status === "Received"
    ).length;
    const sentTotal = filtered.filter((item) => item.Status === "Sent").length;
    const ordered = filtered.length;

    setTotalPrice(sum);
    setTotalReceivedCal(receivedTotal);
    setTotalSent(sentTotal);
    setTotalOrder(ordered);
  };

  const applyFilters = (data, month, year) => {
    let filtered = filterDataByYear(data, year);
    filtered = filterDataByMonth(filtered, month);
    return filtered;
  };

  const filterDataByYear = (data, year) => {
    return data.filter((item) => {
      const itemYear = new Date(item.TimeStamp).getFullYear();
      return itemYear === parseInt(year, 10);
    });
  };

  const filterDataByMonth = (data, month) => {
    return data.filter(
      (item) => parseMonthFromOrderNumber(item.OrderNumber) === month
    );
  };

  const handleYearChange = (event) => {
    const year = event.target.value;
    filterByMonthAndYear(selectedMonth, year);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/otherItems`);
      const fetchedData = response.data.map((item) => ({
        ...item,
        imageUrl1: `${apiUrl}/uploads/${item.ImageFilename1}`,
        imageUrl2: `${apiUrl}/uploads/${item.ImageFilename2}`,
      }));
      const sortedData = sortByOrderNumber(fetchedData);
      setData(sortedData);
      setFilteredData(
        filterDataByMonth(sortedData, selectedMonth, selectedYear)
      ); // Filter data by current month
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  const updateStatus = async (itemId, ourStat) => {
    try {
      setLoading(true);
      await axios.put(`${apiUrl}/updateOurStatOther`, { id: itemId, ourStat });
      setLoading(false);
      // Fetch updated data after successful status update
      fetchData();
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  const updateReceiver = async (itemId, receiver) => {
    try {
      setLoading(true);
      await axios.put(`${apiUrl}/updateReceiverOther`, {
        id: itemId,
        receiver,
      });
      setLoading(false);
      // Fetch updated data after successful status update
      fetchData();
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  const handleOptionSelect = (index, itemId, ourStat) => {
    setDropdownOpen(null); // Close the dropdown

    // Update the status in the frontend optimistically
    setData(
      data.map((item) =>
        item.ID === itemId ? { ...item, ourStat: ourStat } : item
      )
    );

    // Update the status in the backend
    updateStatus(itemId, ourStat);
  };

  const handleOptionSelectReceiver = (index, itemId, receiver) => {
    setDropdownOpenReceiver(null); // Close the dropdown
    // Update the status in the frontend optimistically
    setData(
      data.map((item) =>
        item.ID === itemId ? { ...item, receiver: receiver } : item
      )
    );
    // Update the status in the backend
    updateReceiver(itemId, receiver);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Adjust this to your preference

  const [initialData, setInitialData] = useState({}); // Store the initial row data when editing starts

  const [isEditing, setIsEditing] = useState(null);
  const [editData, setEditData] = useState({
    model: "",
    price: "",
    delivery: "",
    rate: "",
    carrier: "",
    buyer: "",
    soldPrice: "",
    status: "",
    orderedBy: "",
    com1: "",
    com2: "",
  });

  const handleEditClick = (item) => {
    setIsEditing(item.ID);
    setInitialData({
      model: item.Model,
      price: item.Price,
      delivery: item.DeliveryFee,
      rate: item.rate,
      carrier: item.carrier,
      buyer: item.buyer,
      soldPrice: item.soldPrice,
      status: item.Status,
      orderedBy: item.orderedBy,
      com1: item.com1,
      com2: item.com2,
    });
    setEditData({}); // Start with an empty object
  };

  const handleChange = (field, value) => {
    setEditData((prev) => ({
      ...prev,
      [field]: value !== initialData[field] ? value : undefined, // Set value if changed, otherwise undefined
    }));
  };

  // Function to handle saving the edited data
  const handleSave = async (id) => {
    const completeEditData = {
      model: editData.model !== undefined ? editData.model : initialData.model,
      price: editData.price !== undefined ? editData.price : initialData.price,
      delivery:
        editData.delivery !== undefined
          ? editData.delivery
          : initialData.delivery,
      rate: editData.rate !== undefined ? editData.rate : initialData.rate,
      carrier:
        editData.carrier !== undefined ? editData.carrier : initialData.carrier,
      buyer: editData.buyer !== undefined ? editData.buyer : initialData.buyer,
      soldPrice:
        editData.soldPrice !== undefined
          ? editData.soldPrice
          : initialData.soldPrice,
      orderedBy:
        editData.orderedBy !== undefined
          ? editData.orderedBy
          : initialData.orderedBy,
      com1: editData.com1 !== undefined ? editData.com1 : initialData.com1,
      com2: editData.com2 !== undefined ? editData.com2 : initialData.com2,
      status:
        editData.status !== undefined ? editData.status : initialData.status,
    };

    try {
      setLoading(true);
      const response = await axios.put(
        `${apiUrl}/updateOtherItem/${id}`,
        completeEditData
      );
      setData(data.map((item) => (item.ID === id ? response.data : item)));
      setIsEditing(null);
      setLoading(false);
      fetchData();
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };

  const filterByModel = (data, model) => {
    if (!model) return data;
    return data.filter((item) => item.Model === model); // Adjust as needed to match your Model field case
  };

  const filterByMonth = (data, month) => {
    if (!month) return data;
    return data.filter((item) => {
      const itemMonth = new Date(item.TimeStamp).getMonth() + 1; // getMonth is 0-indexed
      return itemMonth === parseInt(month);
    });
  };

  const filterByDay = (data, day, month) => {
    if (!day || !month) return data; // Ensure month is selected
    return data.filter((item) => {
      const itemDate = new Date(item.TimeStamp);
      const itemMonth = itemDate.getMonth() + 1;
      const itemDay = itemDate.getDate();
      return itemMonth === parseInt(month) && itemDay === parseInt(day); // Filter by day within the selected month
    });
  };

  const filterStatus = (data, status) => {
    if (!status) return data;
    return data.filter((item) => item.Status === status); // Filter by status
  };

  useEffect(() => {
    let filtered = filteredData;
    filtered = filterByDay(filtered);
    filtered = filterByModel(filtered);
    setFilteredData(filtered);
  }, [filterByDay, filterByModel, filteredData]);
  // Update useEffect to handle filtering
  // useEffect(() => {
  //   let filtered = data;
  //   if (filterMonth) {
  //     filtered = filterByMonth(filtered, filterMonth);
  //   }
  //   if (filterDay && filterMonth) {
  //     // Only filter by day if month is selected
  //     filtered = filterByDay(filtered, filterDay, filterMonth);
  //   }
  //   if (filterStatusP) {
  //     filtered = filterStatus(filtered, filterStatusP);
  //   }
  //   if (filterModel) {
  //     // Add this line to filter by model name
  //     filtered = filterByModel(filtered, filterModel);
  //   }
  //   setFilteredData(filtered);
  // }, [data, filterMonth, filterDay, filterStatusP, filterModel]);

  // Calculate the number of days for the selected month
  const getDaysInMonth = (month) => {
    const year = new Date().getFullYear(); // Use the current year
    return new Date(year, month, 0).getDate(); // Get the last day of the month
  };

  const handleClear = () => {
    setFilterMonth("");
    setFilterDay("");
    setFilterStatusP("");
    setFilterModel("");
  };

  const filterByMonthAll = (month) => {
    if (month === "") {
      setFilteredData(data);
    } else {
      const filtered = data.filter((item) =>
        item.OrderNumber.startsWith(`OP${month}`)
      );
      setFilteredData(filtered);
      setSelectedMonth(month);
      const sum = filtered.reduce(
        (acc, item) => acc + parseFloat(item.Price || 0),
        0
      );
      const receivedTotal = filtered.filter(
        (item) => item.Status === "Received"
      ).length;
      const sentTotal = filtered.filter(
        (item) => item.Status === "Sent"
      ).length;
      const ordered = filtered.length;

      setTotalSent(sentTotal);
      setTotalReceivedCal(receivedTotal);
      setTotalPrice(sum);
      setTotalOrder(ordered);
    }
  };

  const handleMonthSelect = (month) => {
    setSelectedMonth(month);
    filterByMonthAll(month); // Hide the dropdown after selection
  };
  const [isMobile, setIsMobile] = useState(false);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // Mobile if width <= 768px
  };

  useEffect(() => {
    handleResize(); // Check initially
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const totalExpense = filteredData.reduce(
    (acc, item) => acc + Number(item.price || 0),
    0
  );
  const totalCom1 = filteredData.reduce(
    (acc, item) => acc + Number(item.com1 || 0),
    0
  );
  const totalCom2 = filteredData.reduce(
    (acc, item) => acc + Number(item.com2 || 0),
    0
  );
  const totalDelivery = filteredData.reduce(
    (acc, item) => acc + Number(item.Delivery || 0),
    0
  );
  return (
    <div className="justify-center align-center border-1 border-slate-200 mt-20 w-11/12 mb-20 h-screen px-5 rounded-md">
      <div className="flex flex-col my-2">
        <h1 className="text-3xl font-bold text-gray-800">Other Products</h1>
      </div>
      {isMobile ? (
        <div className="flex flex-col my-2">
          <select
            className="border p-2 mr-2"
            value={selectedMonth}
            onChange={(e) => handleMonthSelect(e.target.value)}
          >
            <option value="" disabled>
              Select Month
            </option>
            {months.map((month) => (
              <option
                key={month}
                value={month}
                onClick={() => filterByMonthAll(month)}
              >
                {month}
              </option>
            ))}
          </select>
          <select
            name="filterDay"
            id="filterDay"
            className="border p-2 mr-2 my-2"
            value={filterDay}
            onChange={(e) => setFilterDay(e.target.value)}
            disabled={!filterMonth} // Disable the day filter if no month is selected
          >
            <option value="" disabled>
              Filter by Day
            </option>
            {filterMonth &&
              Array.from(
                { length: getDaysInMonth(filterMonth) },
                (_, i) => i + 1
              ).map((day) => (
                <option key={day} value={day}>
                  {day}
                </option>
              ))}
          </select>
          <select
            name="filterStatusP"
            id="filterStatusP"
            className="border p-2 mr-2 mb-2"
            value={filterStatusP}
            onChange={(e) => setFilterStatusP(e.target.value)}
          >
            <option value="" disabled>
              Filter by Status
            </option>
            <option value="Ordered">Ordered</option>
            <option value="Received">Received</option>
            <option value="Sent">Sent</option>
            <option value="Return Received">Return Received</option>
            <option value="Return Sent">Return Sent</option>
          </select>
          <input
            type="text"
            name="model"
            id="model"
            className="border p-2 mr-2 mb-2"
            value={filterModel}
            onChange={(e) => setFilterModel(e.target.value)}
          />
          <button
            onClick={handleClear}
            className="bg-slate-600 hover:bg-slate-500 text-white font-bold px-4 mb-2 rounded"
          >
            Clear
          </button>
        </div>
      ) : (
        <div className="flex flex-row my-2">
          <select
            className="border p-2 mr-2"
            value={selectedMonth}
            onChange={(e) => handleMonthSelect(e.target.value)}
          >
            <option value="" disabled>
              Select Month
            </option>
            {months.map((month) => (
              <option
                key={month}
                value={month}
                onClick={() => filterByMonthAll(month)}
              >
                {month}
              </option>
            ))}
          </select>
          <label htmlFor="year-select">Year:</label>
          <input
            id="year-select"
            type="number"
            value={selectedYear}
            onChange={handleYearChange}
            min="2000"
            max={new Date().getFullYear()}
          />
          <select
            name="filterDay"
            id="filterDay"
            className="border p-2 mr-2"
            value={filterDay}
            onChange={(e) => setFilterDay(e.target.value)}
            disabled={!filterMonth} // Disable the day filter if no month is selected
          >
            <option value="" disabled>
              Filter by Day
            </option>
            {filterMonth &&
              Array.from(
                { length: getDaysInMonth(filterMonth) },
                (_, i) => i + 1
              ).map((day) => (
                <option key={day} value={day}>
                  {day}
                </option>
              ))}
          </select>

          <select
            name="filterStatusP"
            id="filterStatusP"
            className="border p-2 mr-2"
            value={filterStatusP}
            onChange={(e) => setFilterStatusP(e.target.value)}
          >
            <option value="" disabled>
              Filter by Status
            </option>
            <option value="Ordered">Ordered</option>
            <option value="Received">Received</option>
            <option value="Sent">Sent</option>
            <option value="Return Received">Return Received</option>
            <option value="Return Sent">Return Sent</option>
          </select>

          <button
            onClick={handleClear}
            className="bg-slate-600 hover:bg-slate-500 text-white font-bold px-4 rounded"
          >
            Clear
          </button>
        </div>
      )}
      {/* Conditionally render the select for mobile screens */}
      {isMobile ? (
        <select
          className="border p-2 mr-2"
          value={selectedMonth}
          onChange={(e) => handleMonthSelect(e.target.value)}
        >
          <option value="" disabled>
            Select Month
          </option>
          {months.map((month) => (
            <option
              key={month}
              value={month}
              onClick={() => filterByMonthAll(month)}
            >
              {month}
            </option>
          ))}
        </select>
      ) : (
        <div className="flex justify-center mb-4">
          {months.map((month) => (
            <button
              key={month}
              onClick={() => {
                filterByMonthAll(month);
              }}
              className={`px-4 py-2 rounded-md mx-2 ${
                selectedMonth === month
                  ? "bg-blue-600 text-white"
                  : "bg-gray-200"
              }`}
            >
              {month}
            </button>
          ))}
          <button
            onClick={() => {
              setSelectedMonth("");
              filterByMonthAll("");
            }}
            className={`px-4 py-2 rounded-md mx-2 ${
              selectedMonth === "" && selectedMonth !== months
                ? "bg-blue-600 text-white"
                : "bg-gray-200"
            }`}
          >
            All
          </button>
        </div>
      )}
      <div className="relative max-h-[80vh] overflow-y-auto custom-scrollbar">
        <table className="w-full border-collapse">
          <thead className="sticky top-0 z-10 border-b border-slate-600 text-left bg-white">
            <tr>
              <th className="sticky top-0 z-10 border-b border-slate-600 text-left  bg-gray-100">
                #
              </th>
              <th className="sticky top-0 z-10 border-b border-slate-600 text-left bg-gray-200">
                ItemName
              </th>
              <th className="sticky top-0 z-10 border-b border-slate-600 text-left bg-gray-100">
                Price
              </th>
              <th className="sticky top-0 z-10 border-b border-slate-600 text-left bg-gray-200">
                Delivery Fee
              </th>
              <th className="sticky top-0 z-10 border-b border-slate-600 text-left bg-gray-200">
                Rate
              </th>
              <th className="sticky top-0 z-10 border-b border-slate-600 text-left bg-gray-100">
                Carrier
              </th>
              <th className="sticky top-0 z-10 border-b border-slate-600 text-left bg-gray-200">
                Com1
              </th>
              <th className="sticky top-0 z-10 border-b border-slate-600 text-left bg-gray-100">
                Com2
              </th>
              <th className="sticky top-0 z-10 border-b border-slate-600 text-left bg-gray-100">
                Total Y
              </th>
              <th className="sticky top-0 z-10 border-b border-slate-600 text-left bg-gray-200">
                ETB
              </th>
              <th className="sticky top-0 z-10 border-b border-slate-600 text-left bg-gray-100">
                Buyer
              </th>
              <th className="sticky top-0 z-10 border-b border-slate-600 text-left bg-gray-200">
                Price
              </th>
              <th className="sticky top-0 z-10 border-b border-slate-600 text-left bg-gray-100">
                Profit
              </th>
              <th className="sticky top-0 z-10 border-b border-slate-600 text-left bg-gray-200">
                Status
              </th>
              <th className="sticky top-0 z-10 border-b border-slate-600 text-left bg-gray-100">
                Receiver
              </th>
              <th className="sticky top-0 z-10 border-b border-slate-600 text-left bg-gray-200">
                OrderedBy
              </th>
              <th className="sticky top-0 z-10 border-b border-slate-600 text-left bg-gray-100">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => {
              const price = Number(item.price) || 0;
              const com1 = Number(item.com1) || 0;
              const com2 = Number(item.com2) || 0;
              const deliveryFee = Number(item.Delivery) || 0;
              const totalYuan = () => {
                const sum = price + com1 + com2 + deliveryFee;
                totalYuanSum += sum;
                return sum;
              };
              const totalBirr = () => {
                const rate = Number(item.rate);
                const carrier = Number(item.carrier);
                const sum =
                  (price + com1 + com2 + deliveryFee) * rate + carrier;
                return sum;
              };
              const profit = () => {
                const profitValue = item.soldPrice - totalBirr();
                totalProfit += profitValue; // Accumulate total profit
                return profitValue;
              };
              return (
                <tr key={item.ID} className=" border-b-1 border-slate-600">
                  {isEditing === item.ID ? (
                    <>
                      <td className="text-left m-0 bg-gray-100">
                        {item.OrderNumber}
                      </td>
                      <td className="text-left m-0 bg-gray-200">
                        <input
                          className="border w-full"
                          type="text"
                          value={
                            editData.model !== undefined
                              ? editData.model
                              : item.ItemName
                          }
                          onChange={(e) =>
                            handleChange("model", e.target.value)
                          }
                        />
                      </td>
                      <td className="text-left m-0 bg-gray-100">
                        <input
                          className="border w-full"
                          type="text"
                          placeholder="price"
                          value={
                            editData.price !== undefined
                              ? editData.price
                              : item.price
                          }
                          onChange={(e) =>
                            handleChange("price", e.target.value)
                          }
                        />
                      </td>
                      <td className="text-left m-0 bg-gray-200">
                        <input
                          className="border w-full"
                          type="text"
                          placeholder="delivery"
                          value={
                            editData.delivery !== undefined
                              ? editData.delivery
                              : item.Delivery
                          }
                          onChange={(e) =>
                            handleChange("delivery", e.target.value)
                          }
                        />
                      </td>
                      <td className="text-left m-0 bg-gray-200">
                        <input
                          className="border w-full"
                          type="text"
                          placeholder="rate"
                          value={
                            editData.rate !== undefined
                              ? editData.rate
                              : item.rate
                          }
                          onChange={(e) => handleChange("rate", e.target.value)}
                        />
                      </td>
                      <td className="text-left m-0 bg-gray-100">
                        <input
                          className="border w-full"
                          type="text"
                          placeholder="carrier"
                          value={
                            editData.carrier !== undefined
                              ? editData.carrier
                              : item.carrier
                          }
                          onChange={(e) =>
                            handleChange("carrier", e.target.value)
                          }
                        />
                      </td>
                      <td className="text-left m-0 bg-gray-200">
                        <input
                          className="border w-full"
                          type="text"
                          placeholder="com1"
                          value={
                            editData.com1 !== undefined
                              ? editData.com1
                              : item.com1
                          }
                          onChange={(e) => handleChange("com1", e.target.value)}
                        />
                      </td>
                      <td className="text-left m-0 bg-gray-100">
                        {totalYuan()}
                      </td>
                      <td className="text-left m-0 bg-gray-100">
                        <input
                          className="border w-full"
                          type="text"
                          placeholder="com2"
                          value={
                            editData.com2 !== undefined
                              ? editData.com2
                              : item.com2
                          }
                          onChange={(e) => handleChange("com2", e.target.value)}
                        />
                      </td>

                      <td className="text-left m-0 bg-gray-200">
                        {totalBirr()}
                      </td>
                      <td className="text-left m-0 bg-gray-100">
                        <input
                          className="border w-full"
                          type="text"
                          placeholder="buyer"
                          value={
                            editData.buyer !== undefined
                              ? editData.buyer
                              : item.buyer
                          }
                          onChange={(e) =>
                            handleChange("buyer", e.target.value)
                          }
                        />
                      </td>
                      <td className="text-left m-0 bg-gray-200">
                        <input
                          className="border w-full"
                          type="text"
                          placeholder="price"
                          value={
                            editData.soldPrice !== undefined
                              ? editData.soldPrice
                              : item.soldPrice
                          }
                          onChange={(e) =>
                            handleChange("soldPrice", e.target.value)
                          }
                        />
                      </td>
                      <td className="text-left m-0 bg-gray-100">{profit()}</td>
                      <td className="text-left m-0 bg-gray-200">
                        <div className="relative inline-block text-left">
                          <div className="flex flex-row justify-around">
                            <p className="inline-flex justify-around gap-x-1.5 rounded-md bg-gray-100  w-20 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-gray-50">
                              {item.Status || "Options"}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="text-left m-0 bg-gray-100">
                        <div className="relative inline-block text-left">
                          <div className="flex flex-row justify-around">
                            <p className="inline-flex justify-around gap-x-1.5 rounded-md bg-gray-100  w-20 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-gray-50">
                              {item.receiver || "Options"}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="text-left m-0 bg-gray-200">
                        <input
                          className="border w-full"
                          type="text"
                          placeholder="orderedBy"
                          value={
                            editData.orderedBy !== undefined
                              ? editData.orderedBy
                              : item.orderedBy
                          }
                          onChange={(e) =>
                            handleChange("orderedBy", e.target.value)
                          }
                        />
                      </td>
                      <td className="flex flex-row text-left m-0 bg-gray-100">
                        <button
                          className="inline-flex justify-around gap-x-1.5 rounded-md bg-gray-100  w-12 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-gray-50"
                          onClick={() => handleSave(item.ID)}
                        >
                          Save
                        </button>
                        <button
                          className="inline-flex justify-around gap-x-1.5 rounded-md bg-red-500  w-12 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-red-300"
                          onClick={() => setIsEditing(null)}
                        >
                          Cancel
                        </button>
                      </td>
                    </>
                  ) : (
                    <>
                      <td className="border-b border-slate-600 text-left bg-gray-100">
                        {item.OrderNumber}
                      </td>
                      <td className="border-b border-slate-600 text-left bg-gray-200">
                        {item.ItemName}
                      </td>
                      <td className="border-b border-slate-600 text-left bg-gray-100">
                        {item.price}
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                        {item.Delivery}
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                        {item.rate}
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                        {item.carrier}
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                        {item.com1}
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                        {item.com2}
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                        {totalYuan()}
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                        {totalBirr()}
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                        {item.buyer}
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                        {item.soldPrice}
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                        {profit()}
                      </td>

                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                        <div className="relative inline-block text-left">
                          <div className="flex flex-row justify-around">
                            <button
                              type="button"
                              className={`inline-flex justify-around gap-x-1.5 rounded-md w-20 mr-1 py-2 text-sm font-semibold shadow-sm hover:bg-gray-500
                                            ${
                                              item.ourStat === "Sold"
                                                ? "bg-green-500 text-white"
                                                : ""
                                            }
                                            ${
                                              item.ourStat === "Received"
                                                ? "bg-blue-500 text-white"
                                                : ""
                                            }
                                            ${
                                              item.ourStat === "Sent"
                                                ? "bg-yellow-500 text-white"
                                                : ""
                                            }
                                            ${
                                              item.ourStat === "Returned"
                                                ? "bg-purple-500 text-white"
                                                : ""
                                            }
                                            ${
                                              item.ourStat === "Unpaid"
                                                ? "bg-red-500 text-white"
                                                : ""
                                            }
                                            ${
                                              !item.ourStat
                                                ? "bg-gray-100 text-blue-900"
                                                : ""
                                            }`}
                              id="menu-button"
                              aria-expanded={dropdownOpen === index}
                              aria-haspopup="true"
                              onClick={() => toggleDropdown(index)}
                            >
                              {item.ourStat || "options"}
                              <svg
                                className="-mr-1 h-5 w-5 text-gray-400"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                          </div>
                          {dropdownOpen === index && (
                            <div
                              className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                              aria-orientation="vertical"
                              aria-labelledby="menu-button"
                              tabIndex="-1"
                            >
                              <div className="py-1" ref={dropdownRef}>
                                <button
                                  className="block w-full text-left text-sm text-gray-700 "
                                  role="menuitem"
                                  tabIndex="-1"
                                  onClick={() =>
                                    handleOptionSelect(index, item.ID, "Sold")
                                  }
                                >
                                  Sold
                                </button>
                                <button
                                  className="block w-full text-left text-sm text-gray-700"
                                  role="menuitem"
                                  tabIndex="-1"
                                  onClick={() =>
                                    handleOptionSelect(
                                      index,
                                      item.ID,
                                      "Received"
                                    )
                                  }
                                >
                                  Received
                                </button>
                                <button
                                  className="block w-full text-left text-sm text-gray-700"
                                  role="menuitem"
                                  tabIndex="-1"
                                  onClick={() =>
                                    handleOptionSelect(index, item.ID, "Custom")
                                  }
                                >
                                  Custom
                                </button>
                                <button
                                  className="block w-full text-left text-sm text-gray-700"
                                  role="menuitem"
                                  tabIndex="-1"
                                  onClick={() =>
                                    handleOptionSelect(
                                      index,
                                      item.ID,
                                      "Returned"
                                    )
                                  }
                                >
                                  Returned
                                </button>
                                <button
                                  className="block w-full text-left text-sm text-gray-700"
                                  role="menuitem"
                                  tabIndex="-1"
                                  onClick={() =>
                                    handleOptionSelect(index, item.ID, "Unpaid")
                                  }
                                >
                                  Unpaid
                                </button>
                                <button
                                  className="block w-full text-left text-sm text-gray-700"
                                  role="menuitem"
                                  tabIndex="-1"
                                  onClick={() =>
                                    handleOptionSelect(index, item.ID, "")
                                  }
                                >
                                  clear
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                        <div className="relative inline-block text-left">
                          <div className="flex flex-row justify-around">
                            <button
                              type="button"
                              className="inline-flex justify-around gap-x-1.5 rounded-md bg-gray-100  w-20 mr-1 py-2 text-sm font-semibold text-blue-900 shadow-sm  hover:bg-gray-50"
                              id="menu-buttonn"
                              aria-expanded={dropdownOpenReceiver === index}
                              aria-haspopup="true"
                              onClick={() => toggleDropdownReceiver(index)}
                            >
                              {item.receiver || "select"}
                              <svg
                                className="-mr-1 h-5 w-5 text-gray-400"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                          </div>
                          {dropdownOpenReceiver === index && (
                            <div
                              className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                              aria-orientation="vertical"
                              aria-labelledby="menu-buttonn"
                              tabIndex="-1"
                            >
                              <div className="py-1" ref={dropdownRefReceiver}>
                                <button
                                  className="block w-full text-left text-sm text-gray-700 hover:bg-gray-700"
                                  role="menuitem"
                                  tabIndex="-1"
                                  onClick={() =>
                                    handleOptionSelectReceiver(
                                      index,
                                      item.ID,
                                      "Yeabkal"
                                    )
                                  }
                                >
                                  Yeabkal
                                </button>
                                <button
                                  className="block w-full text-left text-sm text-gray-700"
                                  role="menuitem"
                                  tabIndex="-1"
                                  onClick={() =>
                                    handleOptionSelectReceiver(
                                      index,
                                      item.ID,
                                      "Natnael"
                                    )
                                  }
                                >
                                  Natnael
                                </button>
                                <button
                                  className="block w-full text-left text-sm text-gray-700"
                                  role="menuitem"
                                  tabIndex="-1"
                                  onClick={() =>
                                    handleOptionSelectReceiver(
                                      index,
                                      item.ID,
                                      "Yeabtsega"
                                    )
                                  }
                                >
                                  Yeabtsega
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200">
                        {item.orderedBy}
                      </td>
                      <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100">
                        <button
                          className="inline-flex justify-around gap-x-1.5 rounded-md bg-gray-700 w-12 mr-1 py-2 text-sm font-semibold  shadow-sm  hover:bg-gray-500 text-white"
                          onClick={() => handleEditClick(item)}
                        >
                          Edit
                        </button>
                      </td>
                    </>
                  )}
                </tr>
              );
            })}
            <tr>
              <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100 font-bold">
                Total
              </td>
              <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200"></td>
              <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100 font-bold">
                {totalExpense}
              </td>
              <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200 font-bold">
                {totalDelivery}
              </td>
              <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100 font-bold"></td>
              <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200"></td>
              <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100 font-bold">
                {totalCom1}
              </td>
              <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200 font-bold">
                {totalCom2}
              </td>
              <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100 font-bold">
                {totalYuanSum}
              </td>
              <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200"></td>
              <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100"></td>
              <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-200"></td>
              <td className="border-b-1 pb-3 border-slate-600 text-left m-0 bg-gray-100 font-bold">
                {totalProfit}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default OtherProducts;
